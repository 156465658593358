import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { logIn, logOut } from '../api/auth';

import { addNotification } from './tooltipSlice';

const AUTH_KEY = 'auth_token';
const URL_KEY = 'base_url';
const DATA_KEY = 'user_data';

const setAuthData = (authToken, baseUrl, userData) => {
  localStorage.setItem(AUTH_KEY, authToken);
  localStorage.setItem(URL_KEY, baseUrl);
  localStorage.setItem(DATA_KEY, JSON.stringify(userData));
};

const clearAuthData = () => {
  localStorage.removeItem(AUTH_KEY);
  localStorage.removeItem(URL_KEY);
  localStorage.removeItem(DATA_KEY);
};

const getAuthData = () => ({
  authToken: localStorage.getItem(AUTH_KEY),
  baseUrl: localStorage.getItem(URL_KEY),
  userData: JSON.parse(localStorage.getItem(DATA_KEY) || '{}'),
});

const isAuthenticated = () => !!localStorage.getItem(AUTH_KEY);

export const loginUser = createAsyncThunk('auth/proccessLogIn', async ({ userEmail, password }) => {
  const response = await logIn(userEmail, password);
  const { auth_token, base_url, ...userData } = response.data;

  setAuthData(auth_token, base_url, userData);

  console.log('Log in passed successfully:', response.data);

  return response.data;
});

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async ({ email, terminal_id, token }, { dispatch, rejectWithValue }) => {
    try {
      const response = await logOut(email, terminal_id, token);
      console.log('Log out passed successfully:', response);

      clearAuthData();

      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to Logout, ${error?.message}`,
          status: 'failed',
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

const initialState = {
  isAuthenticated: isAuthenticated(),
  loginState: {
    user_name: '',
    email: '',
    id: '',
    location_id: '',
    location_name: '',
    auth_token: getAuthData().authToken || null,
    ...getAuthData().userData,
  },
  status: 'idle',
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    checkAuthState: (state) => {
      const token = state.loginState.auth_token || getAuthData().authToken;

      if (token) {
        state.isAuthenticated = true;
        state.loginState.auth_token = token;
      } else {
        state.isAuthenticated = false;
        state.loginState = {
          user_name: '',
          email: '',
          id: '',
          location_id: '',
          location_name: '',
          auth_token: null,
        };
        localStorage.removeItem(AUTH_KEY);
      }
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(loginUser.pending, (state) => {
        setLoading(state);
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        setSucceeded(state);
        state.isAuthenticated = true;
        state.loginState = {
          ...state.loginState,
          ...action.payload,
        };
      })
      .addCase(loginUser.rejected, (state, action) => {
        setFailed(state);
        state.error = action.payload;
      })
      .addCase(logoutUser.pending, (state) => {
        setLoading(state);
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        setSucceeded(state);
        state.isAuthenticated = false;
        state.loginState = state.loginState = initialState.loginState;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        setFailed(state);
        state.error = action.payload;
      });
  },
});

export const { checkAuthState } = authSlice.actions;
export default authSlice.reducer;
