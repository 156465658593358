import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';

import { useAppContext } from "../../../../context/context";

import {
  fetchServiceCharges,
  createServiceCharge,
  updateExistingServiceCharge,
  removeServiceCharge,
  updateExistingChargeStatus,
} from "../../../../redux/serviceChargesSlice";

import { FaCheck } from "react-icons/fa";
import styles from "./service-charges.module.scss";

const ServiceChargeForm = ({
  id,
  typeName,
  initialName = "",
  initialRate = "",
  initialStatus = false,
  initialMinGuestCount,
  initialMaxGuestCount,
  isFormForCreating = false,
  setFormForNewCharge = () => {},
}) => {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const [name, setName] = useState(initialName);
  const [rate, setRate] = useState(initialRate);
  const [status, setStatus] = useState(initialStatus);
  const [minGuest, setMinGuest] = useState(initialMinGuestCount);
  const [maxGuest, setMaxGuest] = useState(initialMaxGuestCount);
  const [formStatus, setFormStatus] = useState(initialStatus);
  const [redactedTypeName, setRedactedTypeName] = useState(typeName === "TakeOutAndParkOrder" ? "Take Out & Open Order" : typeName);

  useEffect(() => {
    setName(initialName);
    setRate(initialRate);
    setStatus(initialStatus);
    setMinGuest(initialMinGuestCount);
    setMaxGuest(initialMaxGuestCount);
  }, [initialName, initialRate, initialStatus, initialMinGuestCount, initialMaxGuestCount]);

  const handleCheckboxChange = useCallback(() => {
    dispatch(updateExistingChargeStatus({ id, is_enabled: !status, token }));
    setStatus((prevStatus) => {
      dispatch(fetchServiceCharges(token));
      return !prevStatus;
    });
  }, [dispatch, id, status, token]);

  const handleCheckboxSwitch = () => {
    setFormStatus((prevStatus) => !prevStatus);
  };

  const chargeDetails = {
    name: name,
    percentage: +rate,
    is_enabled: status,
    min_guest_count: +minGuest,
    max_guest_count: +maxGuest,
    location_id: locationId,
    order_type: typeName,
  }

  const handleSave = () => {
    dispatch(
      updateExistingServiceCharge({
        id,
        updatedDetails: chargeDetails,
        token,
      })
    );
  };

  const handleCreateNewCharge = () => {
    dispatch(
      createServiceCharge({
        newChargeDetails: chargeDetails,
        token: token,
      })
    );

    setFormForNewCharge([]);
  };

  const handleDelete = useCallback(() => {
    dispatch(removeServiceCharge({ id, token }));
  }, [dispatch, id, token]);

  return (
    <form className={styles.form}>
      {typeName !== "DineIn" && (
        <h4 className={styles.subtitle}>
          Setup Service Charge for {redactedTypeName}
        </h4>
      )}

      <div className={styles.form_fields}>
        <div className={styles.checkbox_wrapper}>
          <input
            type="checkbox"
            id={`enable_for_${id}`}
            onChange={isFormForCreating ? handleCheckboxSwitch : handleCheckboxChange}
            checked={isFormForCreating ? formStatus : status}
            className={styles.checkbox_input}
          />
          <label htmlFor={`enable_for_${id}`} className={styles.checkbox_label}>
            <span className={styles.custom_checkbox}>
              {(isFormForCreating ? formStatus : status) && (
                <FaCheck className={styles.check_icon} width="20px" />
              )}
            </span>
            Enable Service Charge for {redactedTypeName}
          </label>
        </div>

        <div className={styles.inputs}>
          <div className={styles.input_wrapper}>
            <label className={styles.input_label}>
              Service Charge Name<span>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={styles.input_wrapper}>
            <label className={styles.input_label}>
              Service Rate (%)<span>*</span>
            </label>
            <input
              className={styles.input}
              type="number"
              required
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              placeholder="0.00%"
            />
          </div>
          {typeName === "DineIn" && (
            <>
              <div className={styles.input_wrapper}>
                <label className={styles.input_label}>
                  Min guest<span>*</span>
                </label>
                <input
                  className={styles.input}
                  type="number"
                  required
                  value={minGuest}
                  onChange={(e) => setMinGuest(e.target.value)}
                  placeholder="100000"
                />
              </div>
              <div className={styles.input_wrapper}>
                <label className={styles.input_label}>
                  Max guest<span>*</span>
                </label>
                <input
                  className={styles.input}
                  type="number"
                  required
                  value={maxGuest}
                  onChange={(e) => setMaxGuest(e.target.value)}
                  placeholder="500000"
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.buttons_wrapper}>
          <button
            className={styles.submitButton}
            type="button"
            onClick={isFormForCreating ? handleCreateNewCharge : handleSave}
          >
            Save
          </button>

          {typeName === "DineIn" && !isFormForCreating && (
            <button
              className={styles.deleteButton}
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

ServiceChargeForm.propTypes = {
  id: PropTypes.number,
  typeName: PropTypes.string,
  initialName: PropTypes.string,
  initialRate: PropTypes.number,
  initialStatus: PropTypes.bool,
  initialMinGuestCount: PropTypes.number,
  initialMaxGuestCount: PropTypes.number,
  setFormForNewCharge: PropTypes.func,
  isFormForCreating: PropTypes.bool,
};

export default ServiceChargeForm;
