import React, { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../../context/context';

import {
  fetchKitchenReceiptSettings,
  updateKitchenReceiptSettings,
  updateState,
} from '../../../../redux/kitchenReceiptSettingsSlice';

import Button from '../../../utilities/button/button';
import PageTitle from '../../../utilities/page-title/page-title';
import KitchenReceiptSettingsForm from './form/kitchen-receipt-settings-form';
import KitchenReceiptExample from './kitchen-receipt-example';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import styles from './kitchen-receipt-settings.module.scss';

function KitchenReceiptSettings() {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const receiptSettingsStatus = useSelector((state) => state.kitchenReceiptSettings.status);
  const initialReceiptSettings = useSelector((state) => state.kitchenReceiptSettings.initialKitchenReceiptSettings);
  const receiptSettings = useSelector((state) => state.kitchenReceiptSettings.kitchenReceiptSettings);

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(receiptSettings) !== JSON.stringify(initialReceiptSettings),
    [initialReceiptSettings, receiptSettings],
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchKitchenReceiptSettings({ token }));
    }
  }, [dispatch, token]);

  const handleFormChange = (id, checked) => {
    const updatedDetails = {
      ...receiptSettings,
      [id]: checked,
    };

    dispatch(updateState(updatedDetails));
  };

  const handleSubmit = () => {
    dispatch(updateKitchenReceiptSettings({ id: receiptSettings.id, updatedDetails: receiptSettings, token }));
  };

  return (
    <>
      <PageTitle name={'Kitchen Receipt Settings'} />
      {receiptSettingsStatus === 'loading' ? <BackdropLoading /> : null}
      <div className={styles.main_content}>
        <div className={styles.form}>
          <KitchenReceiptSettingsForm settings={receiptSettings} onFormChange={handleFormChange} />
          <Button title="Save" onClick={handleSubmit} disabled={!hasUnsavedChanges} style={{ marginBottom: 0 }} />
        </div>
        <KitchenReceiptExample settings={receiptSettings} />
      </div>
    </>
  );
}

export default KitchenReceiptSettings;
