import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { updateExistingOption } from '../../../../redux/optionSetsSlice';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';
import DynamicFormFields from '../../../utilities/dynamic-form-fields/dynamic-form-fields ';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { z } from 'zod';

import OptionForm from './forms/option-form';

import clsx from 'clsx';
import styles from './options.module.scss';

const registrationSchema = z.object({
  name: z.string().min(1, 'Option name is required'),
});

const getOptionSet = (orders, id) => {
  return orders.find((order) => order.id === parseFloat(id));
};

const EditOptionSet = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const optionSetsTypesStatus = useSelector((state) => state.optionSets.status);
  const optionSets = useSelector((state) => state.optionSets.optionSets);
  const [option, setOption] = useState(getOptionSet(optionSets, id));
  const [errors, setErrors] = useState({});

  const statusLoading = optionSetsTypesStatus === 'loading';

  const handleInputChange = useCallback(
    (e, type) => {
      const { name, value } = e.target;
      setOption((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setOption],
  );

  const memoizedOnChange = useCallback(
    (value) => handleInputChange({ target: { value, name: 'options' } }),
    [handleInputChange],
  );

  const handleCancel = () => {
    navigate('/menu/options');
  };

  const handleSaveChanges = async () => {
    const result = registrationSchema.safeParse(option);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      setErrors({});

      try {
        await dispatch(updateExistingOption({ id, updatedDetails: option, token })).unwrap();
        navigate('/menu/options');
      } catch {}
    }
  };

  return (
    <section className={styles.option_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Option Set'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Option Set Name'}
                  value={option.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                  errors={errors.name?._errors}
                />
              </div>
              <div className={clsx(styles.field, styles.block)}>
                <DynamicFormFields
                  value={option.options}
                  fieldItem={OptionForm}
                  label={'Options'}
                  fieldProps={{ style: { margin: '0 auto' } }}
                  addButtonTitle={'Add option'}
                  onChange={memoizedOnChange}
                  bottomLine={true}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Save Option Set'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditOptionSet;
