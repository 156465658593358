import React, { useState, useEffect, useCallback } from "react";
import { RxCross2 } from "react-icons/rx";

import PageTitle from "../../../../../components/utilities/page-title/page-title";
import Modal from "../../../../../components/utilities/modal/modal";
import Input from "../../../../../components/utilities/input/input";
import Label from "../../../../../components/utilities/label/label";
import Selector from "../../../../../components/utilities/selector/selector";
import Button from "../../../../../components/utilities/button/button";

import { IoIosArrowDown } from "react-icons/io";

import clsx from "clsx";
import styles from "./add-variations-modal.module.scss";

const valueConverter = (value, type) => {
  return type === "number" ? parseFloat(value) : value;
};

const initialVariationState = {
  name: "",
  sku: "",
  price_type: 0,
  price: "",
  //quantity: 0,
};

function AddVariationModal({ modal, setModalVisibility, item, onAdd, onEdit }) {
  //console.log("item: ", item );
  //let initialState;

  //console.log("AddVariationModal item: ", item);
  //let flag;

  /*   if (item) {
    initialState = item;
  } else {
    initialState = initialVariationState;
  } */
  // State

  const [newVariation, setNewVariation] = useState(initialVariationState);
  const [action, setAction] = useState("Add");
  const [flag, setFlag] = useState(false);

  const UNIT_OPTIONS = [
    { value: 1, label: "Variable" },
    { value: 2, label: "Fixed" },
  ];

  // Effects
  useEffect(() => {
    if (item) {
      setNewVariation(item);
      setAction("Edit");
    } else {
      setNewVariation(initialVariationState);
      setAction("Add");
    }
  }, [item, action]);

  // Handlers
  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setNewVariation((prev) => ({
      ...prev,
      [name]: valueConverter(value, type),
    }));
    setFlag(true);
  };

  const handleSelectPriceType = (value) => {
    setNewVariation((prev) => ({
      ...prev,
      price_type: Number(value),
    }));
    setFlag(true);
  };

  const handleClickSave = () => {
    if (action === "Add") {
      onAdd(newVariation);
    } else if (action === "Edit") {
      onEdit(newVariation);
    }
    setNewVariation(initialVariationState);
    setModalVisibility(false);
    setFlag(false);
  };


  return (
    <Modal
      isOpen={modal}
      onClose={() => setModalVisibility(false)}
      className={styles.add_variation_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Add variation</h3>
          <div
            className={styles.cross_icon}
            onClick={() => setModalVisibility(false)}
          >
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.input_wrapper}>
            <Input
              label={"Variation name"}
              value={newVariation.name}
              onChange={(e) => handleInputChange(e, "name")}
              placeholder={"Variation Name"}
              required={true}
              name={"name"}
            />
          </div>

          <PageTitle name={"Sales Information"} className={styles.subtitle} />

          <div className={styles.input_wrapper}>
            <Input
              label={"SKU"}
              value={newVariation.sku}
              onChange={(e) => handleInputChange(e, "string")}
              placeholder={"SKU"}
              name={"sku"}
              className={styles.input_variations}
            />
          </div>

          <div className={styles.selector_wrapper}>
            <Label text="Unit"></Label>
            <Selector
              icon={IoIosArrowDown}
              items={UNIT_OPTIONS}
              placeholder={"Choose a price type"}
              onSelectItem={handleSelectPriceType}
              name={"price_type"}
            />
          </div>

          <div className={styles.input_wrapper}>
            <Input
              label={"Price"}
              type="number"
              value={newVariation.price}
              onChange={(e) => handleInputChange(e, "number")}
              placeholder={"$0.00"}
              name={"price"}
              disabled={newVariation.price_type === 1 ? true : false}
              className={styles.input_variations}
            />
          </div>

          <div className={styles.input_wrapper}>
            <Input
              label={"Stock Qty"}
              type="number"
              value={newVariation.quantity}
              onChange={(e) => handleInputChange(e, "number")}
              placeholder={"None"}
              name={"quantity"}
              className={styles.input_variations}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <Button
            className={styles.update_button}
            disabled={!flag}
            title={"Save"}
            onClick={handleClickSave}
          />
          <Button
            className={styles.cancel_button}
            title={"Cancel"}
            onClick={() => setModalVisibility(false)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default AddVariationModal;
