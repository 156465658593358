import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchCategories,
  createCategory,
  updateExistingCategory,
  removeCategory,
  updateExistingCategoryStatus,
} from "../../../../redux/categoriesSlice";
import { addNotification } from "../../../../redux/tooltipSlice";

import { IoIosArrowDown } from "react-icons/io";

import { useAppContext } from "../../../../context/context";

import PageTitle from "../../../../components/utilities/page-title/page-title";
import Selector from "../../../../components//utilities/selector/selector";
import Search from "../../../../components//utilities/search/search";
import Button from "../../../../components/utilities/button/button";

import CategoriesList from "./category-list";
import EditCategoryModal from "./edit-modal";
import AssignItemsCategoryModal from "./assign-items-modal";

import styles from "./categories.module.scss";

const SELECTOR_OPTIONS = [
  { value: "null", label: "All" },
  { value: "true", label: "Active" },
  { value: "false", label: "Inactive" },
];

function Categories() {
  const status = useSelector((state) => state.categories.status);
  const categories = useSelector((state) => state.categories.categories);

  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const [newCategoryName, setNewCategoryName] = useState("");
  const [editableCategoryId, setEditableCategoryId] = useState(null);
  const [editableCategoryName, setEditableCategoryName] = useState("");

  const [isEditCategoryModal, setIsEditCategoryModal] = useState(false);
  const [isAssignItemsModal, setIsAssignItemsModal] = useState(false);

  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("null");

  const [currentCategoryId, setCurrentCategoryId] = useState(null);

  const applyFilters = useCallback(() => {
    let filtered = [...categories].sort((a, b) => a.sort - b.sort);

    if (selectedStatus !== "null") {
      filtered = filtered.filter((category) => {
        const isActive = selectedStatus === "true";
        return category.active === isActive;
      });
    }

    if (searchTerm !== "") {
      filtered = filtered.filter((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredCategories(filtered);
  }, [categories, selectedStatus, searchTerm]);

  useEffect(() => {
    setFilteredCategories(categories);
  }, [categories]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCategories(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    applyFilters();
  }, [categories, selectedStatus, searchTerm, applyFilters]);

  const handleAction = (message, status) => {
    dispatch(addNotification({ message, status }));
  };

  const handleSetResult = (term) => {
    setSearchTerm(term);
  };

  const handleFilterByStatus = (selectedValue) => {
    setSelectedStatus(selectedValue);
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingCategoryStatus({ id, is_active: value, token }));
    },
    [dispatch, token]
  );

  const handleAddNewCategory = () => {
    const newCategoryDetails = {
      name: newCategoryName,
      id: 999,
      active: true,
      location_id: locationId,
      item_ids: [],
      image: "",
    };
    dispatch(createCategory({ newDetails: newCategoryDetails, token }));
    setNewCategoryName("");
  };

  const handleCopyEmployee = (id) => {
    if (!id || !Array.isArray(categories)) {
      handleAction("Invalid ID or category is not an array", "failed");
      return;
    }

    const duplicatedCategory = categories.find((item) => item.id === id);

    if (!duplicatedCategory) {
      handleAction("No category found", "failed");
      return;
    }

    const newCategoryDetails = {
      name: duplicatedCategory.name ? duplicatedCategory.name + 1 : "",
      id: 999,
      active: duplicatedCategory.active ? duplicatedCategory.active : false,
      location_id: locationId,
      item_ids: duplicatedCategory.item_ids ? duplicatedCategory.item_ids : [],
      image: duplicatedCategory.image ? duplicatedCategory.image : "",
    };

    if (!token) {
      handleAction("The token is missing", "failed");
      return;
    }

    dispatch(createCategory({ newDetails: newCategoryDetails, token }));
    handleAction("New category was cloned succeesfully", "succeeded");
  };

  const handleEditCategory = (id) => {
    if (!id || !Array.isArray(categories)) {
      handleAction("Invalid ID or category is not an array", "failed");
      return;
    }
    setEditableCategoryId(id);

    const editableCategory = categories.find((item) => item.id === id);

    if (!id) {
      handleAction("No category found", "failed");
      return;
    }

    setEditableCategoryName(editableCategory.name ? editableCategory.name : "");
    setIsEditCategoryModal(true);
  };

  const handleUpdateCategory = () => {
    if (!editableCategoryId || !Array.isArray(categories)) {
      handleAction("Invalid ID or category is not an array", "failed");
      return;
    }

    const editableCategory = categories.find(
      (item) => item.id === editableCategoryId
    );

    if (!editableCategory) {
      handleAction("No category found", "failed");
      return;
    }

    const updatedDetails = {
      name: editableCategoryName ? editableCategoryName : editableCategory.name,
      id: editableCategory.id ? editableCategory.id : 999,
      active: editableCategory.active ? editableCategory.active : false,
      location_id: editableCategory.location_id
        ? editableCategory.location_id
        : locationId,
      item_ids: editableCategory.item_ids ? editableCategory.item_ids : [],
      image: editableCategory.image ? editableCategory.image : "",
    };

    dispatch(
      updateExistingCategory({ id: editableCategoryId, updatedDetails, token })
    );
    setIsAssignItemsModal(false);
    setIsEditCategoryModal(false);
  };

  const handleDeleteCategory = (id) => {
    dispatch(removeCategory({ id, token }));
  };

  const handleDeleteAllSelected = () => {
    if (selectedCategoriesIds.length > 0) {
      selectedCategoriesIds.forEach((element) => {
        dispatch(removeCategory({ id: element, token }));
      });
    }
  };

  const handleClickAssignItemsLink = useCallback((id) => {
    setIsAssignItemsModal(true);
    setCurrentCategoryId(id);
    setEditableCategoryId(id);
  }, []);

  const handleSearchClean = () => {
    setSearchTerm("");
  };

  const handleCloseModal = useCallback((modalType) => {
    switch (modalType) {
      case "edit_category":
        setIsEditCategoryModal(false);
        break;
      case "assign_items":
        setIsAssignItemsModal(false);
        break;
      default:
        console.warn(`Unknown modal type: ${modalType}`);
    }
  }, []);

  return (
    <>
      <PageTitle name={"Categories"} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.role_selector}>
            <Selector
              icon={IoIosArrowDown}
              items={SELECTOR_OPTIONS}
              placeholder={"Select Status"}
              onSelectItem={handleFilterByStatus}
            />
          </div>
          <div className={styles.search}>
            <Search
              placeholder="Search Category Name"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <div className={styles.new_category_name}>
            <input
              type="text"
              required
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Add New Category Name"
            />
          </div>
          <Button
            className={styles.add_button}
            title={"Add New Category"}
            onClick={handleAddNewCategory}
          />
          <Button
            className={styles.delete_button}
            title={"Delete Selected"}
            onClick={handleDeleteAllSelected}
          />
        </div>
      </div>
      <CategoriesList
        categories={filteredCategories}
        onCopy={handleCopyEmployee}
        onEdit={handleEditCategory}
        onDelete={handleDeleteCategory}
        onToggleActive={handleToggleActive}
        onAssignItemsClick={handleClickAssignItemsLink}
        selectedCategoriesIds={selectedCategoriesIds}
        setSelectedCategoriesIds={setSelectedCategoriesIds}
        isLoading={status === "loading"}
      />
      <EditCategoryModal
        isEditCategoryModal={isEditCategoryModal}
        setIsEditCategoryModal={setIsEditCategoryModal}
        editableCategoryName={editableCategoryName}
        setEditableCategoryName={setEditableCategoryName}
        handleUpdateCategory={handleUpdateCategory}
      />
      <AssignItemsCategoryModal
        categories={filteredCategories}
        isAssignItemsModal={isAssignItemsModal}
        setIsAssignItemsModal={setIsAssignItemsModal}
        handleCloseModal={handleCloseModal}
        handleUpdateCategory={handleUpdateCategory}
      />
    </>
  );
}

export default Categories;
