import React, { useState } from "react";

import List from "../../../../utilities/list/list";
import Label from "../../../../utilities/label/label";

import { IoTrash } from "react-icons/io5";

import styles from "./modifiers-list.module.scss";

const ModifiersList = ({ items, onEdit, onDelete }) => {
  const [itemStates, setItemStates] = useState(
    items.reduce((acc, item) => {
      acc[item.id] = { minimum: 1, maximum: 1 };
      return acc;
    }, {})
  );

  const renderName = (item) => (
    <span className={styles.modifier_name} id={item.id}>
      {item.name}
    </span>
  );

  const renderMinimum = (item) => (
    <div className={styles.wrapper}>
      <Label text="Minimum Modifier" className={styles.label} />
      <input
        type="number"
        id={item.id}
        value={itemStates[item.id]?.minimum}
        onChange={(e) => handleMinimumChanged(item.id, e.target.value)}
        className={styles.input}
      />
    </div>
  );

  const renderMaximum = (item) => (
    <div className={styles.wrapper}>
      <Label text="Maximum Modifier" className={styles.label} />
      <input
        type="number"
        id={item.id}
        value={itemStates[item.id]?.maximum}
        onChange={(e) => handleMaximumChanged(item.id, e.target.value)}
        className={styles.input}
      />
    </div>
  );

  const handleMinimumChanged = (id, value) => {
    const numericValue = Number(value);

    setItemStates((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], minimum: numericValue },
    }));

    onEdit("min", numericValue, id);
  };

  const handleMaximumChanged = (id, value) => {
    const numericValue = Number(value);

    setItemStates((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], maximum: numericValue },
    }));

    onEdit("max", numericValue, id);
  };

  const columnConfig = [
    {
      key: "name",
      render: renderName,
    },
    {
      key: "price",
      render: renderMinimum,
    },
    {
      key: "quantity",
      render: renderMaximum,
    },
  ];

  const rowActions = [
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: "Delete item",
      className: styles.variation_action,
    },
  ];

  let sortedItems = [];

  if (items && items.length) {
    sortedItems = items.sort((a, b) => a.id - b.id);
  }

  return (
    <List
      data={sortedItems}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isDragable={true}
      isWithHeader={false}
      isNoDataBlock={false}
      className={styles.list_wrapper}
    />
  );
};

export default ModifiersList;
