import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';

import { useAppContext } from '../../../../../context/context';
import { fetchOptions } from '../../../../../redux/optionSetsSlice';

import Modal from "../../../../../components/utilities/modal/modal";
import Label from "../../../../../components/utilities/label/label";
import Selector from "../../../../../components/utilities/selector/selector";

import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { IoTrash } from "react-icons/io5";

import clsx from 'clsx';
import styles from "./add-options-modal.module.scss";

function AddOptionsModal({
  modal,
  setModalVisibility,
  onAdd,
}) {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const optionSets = useSelector((state) => state.optionSets.optionSets);

  const [options, setOptions] = useState([]);
  const [selectedOptionsIds, setSelectedOptionsIds] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (token) {
      dispatch(fetchOptions({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if(optionSets && optionSets.length > 0) {
      const transformedOptionSets = optionSets.map(({ id, name, ...properties }) => ({
        label: name,
        value: id,
        properties,
      }));

      setOptions(transformedOptionSets);
    }

  }, [optionSets]);

  useEffect(() => {
    if (selectedOptionsIds && selectedOptionsIds.length > 0) {
      const filteredOptions = options.filter(option =>
        selectedOptionsIds.includes(option.value)
      );
  
      setSelectedOptions(filteredOptions);
    } else {
      setSelectedOptions([]);
    }
  }, [selectedOptionsIds, options]);

  const handleSelectPriceType = (value) => {
    const numericValue = Number(value);

    const exists = selectedOptionsIds.includes(numericValue);
    if (!exists) {
      setSelectedOptionsIds((prev) => [
        ...prev,
        numericValue
      ]);
    } 
  };

  const deleteOptionSet = (value) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter(option => option.value !== value)
    );
    setSelectedOptionsIds((prevSelectedOptionsIds) =>
      prevSelectedOptionsIds.filter(optionId => optionId !== value)
    );
  };

  const removeOptionItem = (value) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.map(option => ({
        ...option,
        properties: {
          ...option.properties,
          options: option.properties.options.filter(item => item.id !== value)
        }
      }))
    );
  }

  const handleClickCreateVariation = () => {
    onAdd(selectedOptions);
    setSelectedOptions([]);
    setModalVisibility(false);
  };

  //console.log('option sets', optionSets);
  //console.log('selectedOptionsIds', selectedOptionsIds);
  //console.log('selectedOptions', selectedOptions);

  return (
    <Modal
      isOpen={modal}
      onClose={() => setModalVisibility(false)}
      className={styles.assign_items_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Add options</h3>
          <div
            className={styles.cross_icon}
            onClick={() => setModalVisibility(false)}
          >
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <span className={styles.description}>Create option sets to group options. For example, a set called "Primary Color" may contain Black and White options</span>
          <div className={styles.selector_wrapper}>
            <Label text="Option Set Name" className={styles.selector_label}></Label>
            <Selector
              icon={IoIosArrowDown}
              items={options}
              placeholder={"Select Option Set"}
              onSelectItem={handleSelectPriceType}
              name={"option_set"}
            />
          </div>
          <div className={styles.selected_options}>
            {(selectedOptions && selectedOptions.length > 0) && selectedOptions.map((option, index) => {
              return (
                <div className={styles.wrap} key={option.value} >
                  <div className={styles.option_header} >
                    <div 
                      //key={option.value} 
                      //className={styles.option_title}
                      className={clsx(
                        styles.option_title, 
                        option.properties?.options?.length === 0 && styles.empty_options
                      )}
                    >
                      {option.label}
                    </div>
                    <div className={styles.delete_icon_wrapper}>
                      <button
                        key={option.value}
                        onClick={() => deleteOptionSet(option.value)}
                        aria-label={"Delete item"}
                        className={styles.delete_button}
                      >
                        <IoTrash />
                      </button>
                    </div>
                  </div>

                  {option.properties?.options?.map((item, index) => {
                    return (
                      <div className={styles.option_item} key={index}>
                        <div className={styles.item_text}>
                          {item.name}
                        </div>
                        <div className={styles.remove_icon_wrapper}>
                          <button
                            key={item.id}
                            onClick={() => removeOptionItem(item.id)}
                            aria-label={"Remove item"}
                            className={styles.cross_button}
                          >
                            <RxCross2 />
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.update_button}
            onClick={handleClickCreateVariation}
          >
            Create Variation
          </button>
          <button
            type="button"
            className={styles.cancel_button}
            onClick={() => setModalVisibility(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddOptionsModal;
