import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getCustomers, getCustomer, addCustomer, updateCustomer, deleteCustomer } from '../api/customers';

// Thunks
export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async ({ token, queryDetails }) => {
  const response = await getCustomers(token, queryDetails);
  return response.data;
});

export const fetchCustomersForLength = createAsyncThunk('customers/fetchCustomersForLength', async ({ token }) => {
  const response = await getCustomers(token);
  return response.data;
});

export const fetchCustomer = createAsyncThunk('customers/fetchCustomer', async ({ id, token }) => {
  const response = await getCustomer(id, token);
  return response.data;
});

export const addNewCustomer = createAsyncThunk('customers/addNewCustomer', async ({ newCustomerDetails, token }) => {
  const response = await addCustomer(newCustomerDetails, token);
  return response.data;
});

export const updateExistingCustomer = createAsyncThunk(
  'customers/updateExistingCustomer',
  async ({ id, updatedDetails, token }) => {
    await updateCustomer(id, updatedDetails, token);
    return { id, updatedDetails };
  },
);

export const removeCustomer = createAsyncThunk('customers/removeCustomer', async ({ id, token }) => {
  await deleteCustomer(id, token);
  return id;
});

const initialState = {
  customers: [],
  currentCustomer: {},
  numberOfAllCustomers: 0,
  queryDetails: {},
  status: 'idle',
  error: null,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchCustomers.pending, setLoading)
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        setSucceeded(state);
        state.customers = action.payload;
      })
      .addCase(fetchCustomers.rejected, setFailed)

      .addCase(fetchCustomersForLength.fulfilled, (state, action) => {
        state.numberOfAllCustomers = action.payload.length;
      })

      .addCase(fetchCustomer.pending, setLoading)
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentCustomer = action.payload;
      })
      .addCase(fetchCustomer.rejected, setFailed)

      .addCase(addNewCustomer.pending, setLoading)
      .addCase(addNewCustomer.fulfilled, (state, action) => {
        setSucceeded(state);
        state.customers.push(action.payload);
      })
      .addCase(addNewCustomer.rejected, setFailed)

      .addCase(updateExistingCustomer.pending, setLoading)
      .addCase(updateExistingCustomer.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.customers.findIndex((cust) => cust.id === updatedDetails.id);

        if (index !== -1) {
          state.customers[index] = updatedDetails;
        }
      })
      .addCase(updateExistingCustomer.rejected, setFailed)

      .addCase(removeCustomer.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.customers = state.customers.filter((cust) => cust.id !== id);
      })
      .addCase(removeCustomer.pending, setLoading)
      .addCase(removeCustomer.rejected, setFailed);
  },
});

export const { setQueryDetails } = customersSlice.actions;
export default customersSlice.reducer;
