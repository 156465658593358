import axios from 'axios';

export const api = axios.create({
  //baseURL: '/',
  baseURL: "https://crunchposdataservice.azurewebsites.net/api/",
  //baseURL: "https://crunchposdataservice-dev.azurewebsites.net/api/",
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};
