import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { addOrderType } from '../../../../redux/orderTypesSlice';

import { IoIosArrowDown } from 'react-icons/io';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Selector from '../../../utilities/selector/selector';
import Button from '../../../utilities/button/button';
import Switch from '../../../utilities/switch/switch';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import clsx from 'clsx';
import styles from './order-types.module.scss';

const OPEN_ORDER_TYPES = [
  { value: 'DineIn', label: 'Dine In' },
  { value: 'TakeOut', label: 'Take Out' },
  { value: 'Delivery', label: 'Delivery' },
  { value: 'Curbside', label: 'Curbside' },
  { value: 'DriveThru', label: 'Drive Thru' },
  { value: 'PickUp', label: 'Pick Up' },
  { value: 'Table', label: 'Table' },
  { value: 'Counter', label: 'Counter' },
  { value: 'Bar', label: 'Bar' },
  { value: 'OpenOrder', label: 'Open Order' },
];

const valueConverter = (value, type) => {
  return type === 'number' ? parseFloat(value) : type === 'boolean' ? !!value : value.toString();
};

const AddOrderType = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderTypesStatus = useSelector((state) => state.orderTypes.status);
  const [order, setOrder] = useState({});

  const statusLoading = orderTypesStatus === 'loading';

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setOrder((prev) => ({
      ...prev,
      [name]: valueConverter(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/business/order_types');
  };

  const handleSaveChanges = async () => {
    try {
      await dispatch(addOrderType({ newOrderDetails: order, token })).unwrap();
      navigate('/business/order_types');
    } catch {}
  };

  return (
    <section className={styles.order_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Create Order Type'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Order Type Name'}
                  value={order.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                />
              </div>
              <div className={styles.field}>
                <Selector
                  label={'Order Type'}
                  icon={IoIosArrowDown}
                  items={OPEN_ORDER_TYPES}
                  selectedItem={order.order_type}
                  onSelectItem={(value) => handleInputChange({ target: { name: 'order_type', value } }, 'text')}
                  name={'order_type'}
                  required={true}
                />
              </div>
              <div className={styles.field}>
                <Input
                  label={'Kitchen Color Code'}
                  value={order.kitchen_color_code || '#000000'}
                  type="color"
                  onChange={handleInputChange}
                  name={'kitchen_color_code'}
                  required={true}
                />
              </div>
            </div>
            <div className={clsx(styles.field, styles.switcher)}>
              <Switch
                id={'is_active'}
                isActive={order.is_active}
                onSwitch={(name, value) => handleInputChange({ target: { name, value } }, 'boolean')}
              />
              <span>Active</span>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Add Order Type'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddOrderType;
