import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getDiscounts,
  addDiscount,
  updateDiscount,
  deleteDiscount,
  updateDiscountStatus,
} from "../api/discounts.js";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchDiscounts = createAsyncThunk(
  "discounts/fetchDiscounts",
  async ({ token }) => {
    const response = await getDiscounts(token);
    return response.data;
  }
);

export const createDiscount = createAsyncThunk(
  "discounts/addDiscount",
  async ({ newDiscountDetails, token }, { dispatch }) => {
    try {
      const response = await addDiscount(newDiscountDetails, token);
      dispatch(
        addNotification({
          message: "Discount created successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to create discount",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateExistingDiscount = createAsyncThunk(
  "discounts/updateExistingDiscount",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateDiscount(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Discount was updated successfully",
          status: "succeeded",
        })
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update discount",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateExistingDiscountStatus = createAsyncThunk(
  "discounts/updateExistingDiscountStatus",
  async ({ id, is_active, token }, { dispatch }) => {
    try {
      await updateDiscountStatus(id, is_active, token);
      dispatch(
        addNotification({
          message: "Discount status updated successfully",
          status: "succeeded",
        })
      );
      return { id, is_active };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update discount status",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const removeDiscount = createAsyncThunk(
  "discounts/removeDiscount",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteDiscount(id, token);
      dispatch(
        addNotification({
          message: "Discount deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to delete discount",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

const initialState = {
  discounts: [],
  status: "idle",
  error: null,
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchDiscounts.pending, setLoading)
      .addCase(fetchDiscounts.fulfilled, (state, action) => {
        setSucceeded(state);
        state.discounts = action.payload;
      })
      .addCase(fetchDiscounts.rejected, setFailed)

      .addCase(createDiscount.pending, setLoading)
      .addCase(createDiscount.fulfilled, (state, action) => {
        setSucceeded(state);
        state.discounts.push(action.payload);
      })
      .addCase(createDiscount.rejected, setFailed)

      .addCase(updateExistingDiscount.pending, setLoading)
      .addCase(updateExistingDiscount.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingDiscount = state.discounts.find((item) => item.id === id);

        if (existingDiscount) {
          Object.assign(existingDiscount, updatedDetails);
        }
      })
      .addCase(updateExistingDiscount.rejected, setFailed)

      .addCase(removeDiscount.fulfilled, (state, action) => {
        state.discounts = state.discounts.filter(
          (item) => item.id !== action.payload
        );
      })

      .addCase(updateExistingDiscountStatus.pending, setLoading)
      .addCase(updateExistingDiscountStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, is_active } = action.payload;
        const existingDiscount = state.discounts.find((item) => item.id === id);
        if (existingDiscount) {
          existingDiscount.is_active = is_active;
        }
      })
      .addCase(updateExistingDiscountStatus.rejected, setFailed);
  },
});

export default discountsSlice.reducer;
