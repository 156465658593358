import { z } from 'zod';

export const registrationSchema = z.object({
  first_name: z
    .string({ required_error: 'Please enter First Name' })
    .min(1)
    .regex(/^[a-zA-Z]+$/, { message: 'Only letters are allowed in First Name.' })
    .max(20, { message: 'Please enter 20 characters or fewer.' }),
  last_name: z
    .string({ required_error: 'Please enter Last Name' })
    .min(1)
    .regex(/^[a-zA-Z]+$/, { message: 'Only letters are allowed in Last Name.' })
    .max(20, { message: 'Please enter 20 characters or fewer.' }),
  email: z.string().min(1).email('Please enter valid Email Address').optional(),
  phone_number: z
    .string()
    // .regex(/^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/, { message: 'Please enter only digit' })
    .max(14, 'Please enter 10 digits only')
    .nullable()
    .optional(),
  passcode: z
    .string({ required_error: 'Please enter Passcode' })
    .min(1)
    .regex(/^\d{4}$/, { message: 'Please enter only digit' })
    .max(4, 'Passcode must be 4 digits'),
  hourly_wages: z
    .number()
    .min(0.01, { message: 'Hourly Rate should be greater than 0.00' })
    .max(10000, { message: 'Employee Hourly Rate should be less than $10,000.' })
    .refine((val) => /^\d+(\.\d{1,2})?$/.test(val.toFixed(2)), { message: 'Only digits are allowed' })
    .optional(),
  team_role_id: z.number({ required_error: 'Please select Team Role' }).min(1),
});
