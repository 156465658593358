import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/context";

import {
  fetchTaxes,
  addTax,
  updateExistingTax,
  removeTax,
  updateExistingTaxStatus,
} from "../../../../redux/taxesSlice";

import PageTitle from "../../../utilities/page-title/page-title";
import Input from "../../../utilities/input/input";
import Button from "../../../utilities/button/button";

import SalesTaxesList from "./sales-taxes-list";
import EditSalesTaxesModal from "./sales-taxes-edit-modal";
import AssignItemsSalesTaxesModal from "./sales-taxes-assign-modal";

import clsx from "clsx";
import styles from "./sales-taxes.module.scss";

const SalesTaxes = () => {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const taxes = useSelector((state) => state.taxes.taxes);
  const taxesStatus = useSelector((state) => state.taxes.status);

  // Add new tax
  const [newTax, setNewTax] = useState("");
  const [newTaxRate, setNewTaxRate] = useState("");
  const [taxType, setTaxType] = useState("Percentage");

  // Modal windows
  const [isAssignItemsModal, setIsAssignItemsModal] = useState(false);
  const [isEditSalesTaxesModal, setIsEditSalesTaxesModal] = useState(false);
  const [editableData, setEditableData] = useState({
    editableItem: {},
    editableName: "",
    editableRate: 0,
    editableType: "",
  });
  const [currentTaxId, setCurrentTaxId] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(fetchTaxes(token));
    }
  }, [dispatch, token]);

  const handleAdd = useCallback(() => {
    const largestId = taxes.reduce(
      (max, tax) => (tax.id > max ? tax.id : max),
      0
    );
    const newDetails = {
      id: largestId + 1,
      is_active: true,
      location_id: locationId,
      name: newTax,
      rate: newTaxRate,
      tax_type: taxType,
    };
    dispatch(addTax({ newDetails, token }));
    setNewTax("");
    setNewTaxRate("");
  }, [dispatch, newTax, newTaxRate, taxType, taxes, locationId, token]);

  const handleSave = useCallback(() => {
    dispatch(
      updateExistingTax({
        id: currentTaxId,
        updatedDetails: {
          name: editableData.editableName,
          rate: editableData.editableRate,
          tax_type: editableData.editableType,
        },
        token,
      })
    );
    setIsEditSalesTaxesModal(false);
    setEditableData({
      editableItem: {},
      editableName: "",
      editableRate: 0,
      editableType: "",
    });
  }, [dispatch, currentTaxId, editableData, token]);

  const handleEdit = useCallback(
    (itemId) => {
      setCurrentTaxId(itemId);
      const Tax = taxes.find((tip) => tip.id === itemId);
      setEditableData({
        editableItem: Tax,
        editableName: Tax.name,
        editableRate: Tax.rate,
        editableType: Tax.tax_type,
      });
      setIsEditSalesTaxesModal(true);
    },
    [taxes]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(removeTax({ id, token }));
    },
    [dispatch, token]
  );

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingTaxStatus({ id, is_active: value, token }));
    },
    [dispatch, token]
  );

  const handleClickAssignItemsLink = useCallback((id) => {
    setIsAssignItemsModal(true);
  }, []);

  const handleUpdateTax = useCallback(() => {
    setIsAssignItemsModal(false);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsAssignItemsModal(false);
  }, []);

  return (
    <div className={styles.taxes}>
      <PageTitle name={"Taxes"} />
      <div className={styles.add_tax}>
        <div className={styles.section}>
          <Input
            label={"Tax Name"}
            value={newTax}
            onChange={(e) => setNewTax(e.target.value)}
            placeholder={"Tax Name"}
            required={true}
            className={styles.tax_name_input}
          />
        </div>
        <div className={clsx(styles.section, styles.tax_rate_wrapper)}>
          <Input
            label={"Tax Rate"}
            type="number"
            value={newTaxRate}
            onChange={(e) => setNewTaxRate(e.target.value)}
            placeholder={"0.00%"}
            required={true}
            className={styles.tax_percent_input}
          />
          <div className={styles.tax_type_buttons}>
            <button
              className={`${styles.tax_type_button} ${taxType === "Percentage" ? styles.active : ""}`}
              onClick={() => setTaxType("Percentage")}
            >
              %
            </button>
            <button
              className={`${styles.tax_type_button} ${taxType === "Dollar" ? styles.active : ""}`}
              onClick={() => setTaxType("Dollar")}
            >
              $
            </button>
          </div>
        </div>
        <Button
          className={styles.add_button}
          title={"Add Tax"}
          onClick={handleAdd}
        />
      </div>
      <SalesTaxesList
        items={taxes}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onAssignToItemsClick={handleClickAssignItemsLink}
        onToggleActive={handleToggleActive}
        isLoading={taxesStatus === "loading"}
      />
      <EditSalesTaxesModal
        isModalOpen={isEditSalesTaxesModal}
        setIsModalOpen={setIsEditSalesTaxesModal}
        editableData={editableData}
        setEditableData={setEditableData}
        onUpdate={handleSave}
      />

      <AssignItemsSalesTaxesModal
        isModalOpen={isAssignItemsModal}
        setIsModalOpen={setIsAssignItemsModal}
        onClose={handleCloseModal}
        onUpdate={handleUpdateTax}
      />
    </div>
  );
};

export default SalesTaxes;
