import { useMemo } from 'react';

import Switch from '../../../../utilities/switch/switch';

import styles from '../end-of-day-report-settings.module.scss';

const DATA_MODEL = [
  { name: 'employee_guest_report', title: 'Show Employee Guest Report' },
  { name: 'credit_tip_audit', title: 'Show Credit Tip Audit' },
  { name: 'sales_and_tax_summary', title: 'Show Sales And Tax Summary' },
  { name: 'cash_credit_per_sales_category_summary', title: 'Show Cash/Credit Per Sales Category Summary' },
  { name: 'order_sales_details', title: 'Show Order Sales Details' },
  { name: 'sales_summary', title: 'Show Sales Summary' },
  { name: 'payment_details', title: 'Show Payment Details' },
  { name: 'tips_details', title: 'Show Tip Details' },
  { name: 'tax_details', title: 'Show Tax Details' },
  { name: 'refund_or_voids', title: 'Show Refund/Voids' },
  { name: 'refund_details', title: 'Show Refund Details' },
  { name: 'discount_details', title: 'Show Discount Details' },
  { name: 'total_credit_payments', title: 'Show Total Credit Payments' },
  { name: 'total_cash_payments', title: 'Show Total Cash Payments' },
  { name: 'total_payments', title: 'Show Total Payments' },
  { name: 'credit_card_breakdown', title: 'Show Credit Card Breakdown' },
  { name: 'service_charge_details', title: 'Show Service Charge Details' },
  { name: 'cash_log_details', title: 'Show Cash log Details' },
  { name: 'other_details', title: 'Show Other Details' },
  { name: 'clock_in_out', title: 'Show Clock In-Out Details' },
  { name: 'item_wise_sales', title: 'Show Item Wise Sales' },
];

const SettingRow = ({ setting, isActive, onFormChange }) => (
  <div className={styles.row}>
    <span>{setting.title}</span>
    <Switch id={setting.name} value={setting.name} isActive={isActive} onSwitch={onFormChange} />
  </div>
);

const KitchenReceiptSettingsForm = ({ settings, onFormChange }) => {
  const activeSettings = useMemo(() => DATA_MODEL.filter((setting) => setting.name in settings), [settings]);

  return (
    <div className={styles.list_controller}>
      <div className={styles.settings}>
        {activeSettings.map((setting) => (
          <SettingRow key={setting.name} setting={setting} isActive={true} onFormChange={onFormChange} />
        ))}
      </div>
    </div>
  );
};

export default KitchenReceiptSettingsForm;
