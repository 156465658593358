import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAppContext } from "../../../../context/context";

import {
  fetchTeamRoles,
  createTeamRole,
  fetchModules,
  fetchEmployees,
} from "../../../../redux/rolesSlice";

import TeamRoleRow from "./team-roles-row";

import clsx from "clsx";
import styles from "./team-roles.module.scss";

function Roles() {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  //const roles = useSelector((state) => state.roles.roles);
  //const modules = useSelector((state) => state.roles.modules);
  //const employees = useSelector((state) => state.roles.employees);

  const { roles, modules, employees } = useSelector((state) => ({
    roles: state.roles.roles,
    modules: state.roles.modules,
    employees: state.roles.employees,
  }));

  const [newRoleName, setNewRoleName] = useState("");
  const [selectedModules, setSelectedModules] = useState([]);
  const [rightBlockModules, setRightBlockModules] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [rightBlockEmployees, setRightBlockEmployees] = useState([]);

  useEffect(() => {
    if (!roles.length || !modules.length || !employees.length) {
      Promise.all([
        dispatch(fetchTeamRoles(token)),
        dispatch(fetchModules(token)),
        dispatch(fetchEmployees(token)),
      ]).catch((error) => console.error("Error fetching data", error));
    }
  }, [dispatch, token, roles.length, modules.length, employees.length]);

  const handleItemClick = (id, selectedItems, setSelectedItems) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((itemId) => itemId !== id)
        : [...prevSelectedItems, id]
    );
  };

  const handleAddAll =
    (
      allItems,
      selectedItems,
      rightBlockItems,
      setRightBlockItems,
      setSelectedItems
    ) =>
    (event) => {
      event.preventDefault();

      const newItems = allItems.filter(
        (item) =>
          selectedItems.includes(item.id) &&
          !rightBlockItems.some((rightItem) => rightItem.id === item.id)
      );

      setRightBlockItems([...rightBlockItems, ...newItems]);
      setSelectedItems([]);
    };

  const handleRemoveAll = (setter) => (event) => {
    event.preventDefault();
    setter([]);
  };

  const handleSaveAddForm = (event) => {
    event.preventDefault();

    const maxId = roles.reduce(
      (max, role) => (role.id > max ? role.id : max),
      0
    );
    const employeeIds = rightBlockEmployees.map((employee) => employee.id);
    const moduleIds = rightBlockModules.map((module) => module.id);

    const newDetails = {
      id: maxId + 1,
      name: newRoleName,
      location_id: locationId,
      employee_ids: employeeIds,
      module_ids: moduleIds,
    };

    dispatch(createTeamRole({ newDetails, token }));
    resetForm();
  };

  const handleCancelAddForm = (event) => {
    event.preventDefault();
    resetForm();
  };

  const resetForm = () => {
    setNewRoleName("");
    setRightBlockModules([]);
    setRightBlockEmployees([]);
  };

  return (
    <>
      <div className={styles.roles}>
        <div className={styles.header}>
          <h2 className={styles.title}>Roles</h2>
        </div>
        <form className={styles.create_role}>
          <div className={styles.role_name}>
            <label>
              Add Role Name<span>*</span>
            </label>
            <input
              type="text"
              value={newRoleName}
              placeholder="Role Name"
              onChange={(e) => setNewRoleName(e.target.value)}
            />
          </div>
          <div className={styles.select_module}>
            <h5 className={styles.subtitle}>Select module</h5>
            <div className={styles.wrapper}>
              <div className={styles.modules_list}>
                {modules
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((module) => (
                    <div
                      key={module.id}
                      onClick={() =>
                        handleItemClick(
                          module.id,
                          selectedModules,
                          setSelectedModules
                        )
                      }
                      className={clsx(styles.module, {
                        [styles.module_selected]: selectedModules.includes(
                          module.id
                        ),
                      })}
                    >
                      {module.name}
                    </div>
                  ))}
              </div>

              <div className={styles.buttons}>
                <button
                  type="button"
                  onClick={handleAddAll(
                    modules,
                    selectedModules,
                    rightBlockModules,
                    setRightBlockModules,
                    setSelectedModules
                  )}
                >
                  Add All
                </button>
                <button
                  type="button"
                  onClick={handleRemoveAll(setRightBlockModules)}
                >
                  Remove All
                </button>
              </div>

              <div className={styles.selected_modules}>
                {rightBlockModules
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((module) => (
                    <div key={module.id} className={styles.right_item}>
                      {module.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.select_employee}>
            <h5 className={styles.subtitle}>Select employee</h5>
            <div className={styles.wrapper}>
              <div className={styles.employee_list}>
                {employees
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((employee) => (
                    <div
                      key={employee.id}
                      onClick={() =>
                        handleItemClick(
                          employee.id,
                          selectedEmployees,
                          setSelectedEmployees
                        )
                      }
                      className={clsx(styles.employee, {
                        [styles.employee_selected]: selectedEmployees.includes(
                          employee.id
                        ),
                      })}
                    >
                      {employee.name}
                    </div>
                  ))}
              </div>

              <div className={styles.buttons}>
                <button
                  type="button"
                  onClick={handleAddAll(
                    employees,
                    selectedEmployees,
                    rightBlockEmployees,
                    setRightBlockEmployees,
                    setSelectedEmployees
                  )}
                >
                  Add All
                </button>
                <button
                  type="button"
                  onClick={handleRemoveAll(setRightBlockEmployees)}
                >
                  Remove All
                </button>
              </div>

              <div className={styles.selected_employees}>
                {rightBlockEmployees
                  .slice()
                  .sort((a, b) => a.id - b.id)
                  .map((employee) => (
                    <div key={employee.id} className={styles.right_item}>
                      {employee.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className={styles.submit_buttons}>
            <button type="button" onClick={handleSaveAddForm}>
              Save
            </button>
            <button type="button" onClick={handleCancelAddForm}>
              Cancel
            </button>
          </div>
        </form>
        <section className={styles.role_table}>
          <div className={styles.table_header}>
            <div className={styles.role}>Role</div>
            <div className={styles.employees_with_role}>
              Employees with Role
            </div>
            <div className={styles.assign_employee}></div>
            <div className={styles.assign_module}></div>
            <div className={styles.actions}>Edit Permission</div>
          </div>
          <div className={styles.table_content}>
            {roles
              .slice()
              .sort((a, b) => a.id - b.id)
              .map((role) => {
                return (
                  <TeamRoleRow
                    key={role.id}
                    id={role.id}
                    name={role.name}
                    employees={role.employees}
                    module_permission={role.module_permission}
                    all_modules={modules}
                    all_employees={employees}
                  />
                );
              })}
          </div>
        </section>
      </div>
    </>
  );
}

export default Roles;
