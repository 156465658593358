import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { forgotPassword } from '../../../api/auth';
import { addNotification } from '../../../redux/tooltipSlice';

import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';
import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';

import { FaArrowLeft } from 'react-icons/fa';

import Logo from '../../../assets/img/snack-logo-black.svg';
import styles from './forgot-password.module.scss';

const registrationSchema = z.object({
  email: z.string().email('Invalid email format'),
});

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginStatus = useSelector((state) => state.auth.status);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = registrationSchema.safeParse({ email });

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      try {
        const response = await forgotPassword(email);

        console.log('Forgot password passed successfully:', response);

        dispatch(
          addNotification({
            message: response.message,
            status: 'succeeded',
          }),
        );

        navigate('/login');
      } catch {
        dispatch(
          addNotification({
            message: 'Error sending password reset link.',
            status: 'failed',
          }),
        );
      }

      setErrors({});
    }
  };

  return (
    <>
      {loginStatus === 'loading' && <BackdropLoading />}
      <div className={styles.wrapper}>
        <div className={styles.login}>
          <img className={styles.logo} src={Logo} draggable={false} alt="Snack POC logo" />
          <h2 className={styles.title}>Forgot your password?</h2>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <Input
                value={email}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                activePlaceholder={true}
                placeholder={'Email Address'}
                errors={errors.email?._errors}
              />
            </div>
            <Button
              title="Send me reset password instructions"
              type="submit"
              className={styles.submit_button}
              style={{ width: '100%', fontSize: '1rem' }}
            />
          </form>
          <div className={styles.back}>
            <Link to="/Login">
              <span>
                <FaArrowLeft />
              </span>
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
