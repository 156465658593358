import React, { useState } from "react";

import { RxHamburgerMenu } from "react-icons/rx";
import { FaUser } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

import Selector from '../../utilities/selector/selector';

import styles from "./header.module.scss";


function Header({ userName, onMenuToggle, locationName }) {
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const languages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'ge', label: 'German' },
  ];

  return (
    <header className={styles.header}>
      <div className={styles.locationInfo} onClick={onMenuToggle}>
        <div className={styles.burgerWrapper} onClick={onMenuToggle}>
          <RxHamburgerMenu />
        </div>
        <h2 className={styles.locationName}>{locationName}</h2>
      </div>

      <div className={styles.userInfo}>
        <div className={styles.userWrapper}>
          <div className={styles.userIcon}>
            <FaUser />
          </div>
          <div className={styles.userName}>{userName}</div>
        </div>
        
        <Selector
          placeholder="Select Language"
          icon={IoIosArrowDown}
          items={languages}
          selectedItem={selectedLanguage}
          onSelectItem={setSelectedLanguage}
        />
      </div>
    </header>
  );
}

export default Header;
