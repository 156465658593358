import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

import { navConfig } from "../../../assets/scripts/pages";

import clsx from "clsx";
import styles from "./sidebar.module.scss";

function Sidebar({
  isOpen,
  onLogout,
  activeParent,
  setActiveParent,
  isMobile,
  isSidebarOpen,
  setIsSidebarOpen,
}) {
  const pathname = window.location.pathname;
  const lastUrlSegment = pathname.substring(pathname.lastIndexOf('/'));

  const activeParentConfig = navConfig.find(
    (item) => item.link === `/${activeParent}`
  );

  const navigate = useNavigate();

  const handleParentClick = (parent, firstSubitem) => {
    const newActiveParent = parent.slice(1);
    setActiveParent(newActiveParent);
    navigate(firstSubitem);
  };

  const handleLinkClick = (elementLink) => {
    if (isMobile) {
      setIsSidebarOpen(false);
    }
  };


  return (
    <aside
      className={clsx(styles.sidebar, {
        [styles.hidden]: !isOpen,
        [styles.expanded]: isMobile && isSidebarOpen,
        [styles.collapsed]: isMobile && !isSidebarOpen,
      })}
    >
      <nav>
        <ul>
          {activeParent === null ? (
            <>
              {navConfig.map((elem, index) => {
                if (elem.isParent) {
                  const parentLink = elem.link;
                  const firstChildLink =
                    elem.subItems[0].link !== ""
                      ? elem.subItems[0].link
                      : elem.subItems[1].link;
                  const fullLink = `${parentLink}${firstChildLink}`;

                  return (
                    <li key={nanoid()}>
                      <Link
                        to={fullLink}
                        onClick={() =>
                          handleParentClick(parentLink, `${fullLink}`)
                        }
                      >
                        {elem.name}
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li key={nanoid()}>
                      <Link to={elem.link} onClick={() => handleLinkClick(elem.link)} className={clsx({
                          [styles.active]: lastUrlSegment === elem.link,
                        })}>
                        {elem.name}
                      </Link>
                    </li>
                  );
                }
              })}
              <li key={nanoid()}>
                <button onClick={onLogout}>Logout</button>
              </li>
            </>
          ) : (
            <>
              {activeParentConfig.subItems &&
                activeParentConfig.subItems.map((elem, index) => {
                  const fullLink = `${activeParentConfig.link}${elem.link}`;
                  if (elem.link) {
                    const str = fullLink;
                    const lastIndex = str.lastIndexOf('/');
                    const lastPart = str.substring(lastIndex);

                    return (
                      <li key={nanoid()}>
                        <Link to={fullLink} onClick={() => handleLinkClick(elem.link)} className={clsx({
                          [styles.active]: lastUrlSegment === lastPart,
                        })}>
                          {elem.name}
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <span key={nanoid()} className={styles.label}>
                        {elem.name}
                      </span>
                    );
                  }
                })}
            </>
          )}
        </ul>
      </nav>
    </aside>
  );
}

export default Sidebar;
