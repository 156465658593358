import endOfDayReportData from './endOfDayReportData.json';

const endOfDayReportStore = (() => {
  let dayReport = endOfDayReportData.data;

  const getDayReport = () => {
    return dayReport;
  };

  const updateDayReport = (updatedData) => {
    dayReport = { ...dayReport, ...updatedData };
  };

  return {
    getDayReport,
    updateDayReport,
  };
})();

export default endOfDayReportStore;
