import Switch from '../../../../utilities/switch/switch';

import styles from '../kitchen-receipt-settings.module.scss';

const KitchenReceiptSettingsForm = ({ settings, onFormChange }) => {
  return (
    <div className={styles.list_controller}>
      <div className={styles.settings}>
        <div className={styles.row}>
          <span>Show Category</span>
          <Switch id={'show_category'} isActive={settings?.show_category} onSwitch={onFormChange} />
        </div>
        <div className={styles.row}>
          <span>Show Employee</span>
          <Switch id={'show_team_member'} isActive={settings?.show_team_member} onSwitch={onFormChange} />
        </div>
        <div className={styles.row}>
          <span>Show Order Note</span>
          <Switch id={'show_order_note'} isActive={settings?.show_order_note} onSwitch={onFormChange} />
        </div>
        <div className={styles.row}>
          <span>Show Order Type</span>
          <Switch id={'show_order_type'} isActive={settings?.show_order_type} onSwitch={onFormChange} />
        </div>
      </div>
      <div>
        <h3 className={styles.customer_title}>Show Customer Information</h3>
        <div className={styles.settings}>
          <div className={styles.row}>
            <span>Name</span>
            <Switch id={'show_customer_name'} isActive={settings?.show_customer_name} onSwitch={onFormChange} />
          </div>
          <div className={styles.row}>
            <span>Mobile</span>
            <Switch id={'show_customer_phone'} isActive={settings?.show_customer_phone} onSwitch={onFormChange} />
          </div>
          <div className={styles.row}>
            <span>Address</span>
            <Switch id={'show_customer_address'} isActive={settings?.show_customer_address} onSwitch={onFormChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KitchenReceiptSettingsForm;
