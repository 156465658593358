import React from "react";

import List from "../../../utilities/list/list";
import Switch from "../../../utilities/switch/switch";

import { IoTrash } from "react-icons/io5";
import styles from "./order-notes-list.module.scss";

const DynamicNotesList = React.memo(
  ({
    items,
    onEdit,
    onListChange,
    editedNotes,
    setEditedNotes,
    onDelete,
    onToggleActive,
    isLoading,
  }) => {
    const renderInputName = (item) => (
      <input
        type="text"
        id={item.id}
        value={
          editedNotes[item.id]?.name !== undefined
            ? editedNotes[item.id].name
            : item.name
        }
        onChange={(e) => onEdit(item.id, "name", e.target.value)}
        className={styles.note_name_input}
      />
    );

    const renderSwitcher = (item) => (
      <Switch
        id={item.id}
        isActive={item.is_active}
        onSwitch={onToggleActive}
      />
    );

    const columnConfig = [
      {
        key: "name",
        header: "Note Name",
        render: renderInputName,
      },
      {
        key: "is_active",
        header: "Status",
        render: renderSwitcher,
      },
    ];

    const rowActions = [
      {
        icon: <IoTrash />,
        onClick: onDelete,
        label: "Delete category",
      },
    ];

    const sortedItems = items.slice().sort((a, b) => a.sort - b.sort);

    return (
      <List
        data={sortedItems}
        columnConfig={columnConfig}
        rowActions={rowActions}
        onChange={onListChange}
        isLoading={isLoading}
        isDragable={true}
        noDataMessage={"No Records found."}
      />
    );
  }
);

export default DynamicNotesList;
