import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchOrderTypes, updateExistingOrderType, removeOrderType } from '../../../../redux/orderTypesSlice';

import { useAppContext } from '../../../../context/context';
import usePagination from './usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';

import OrderTypeList from './order-types-list';

import styles from './order-types.module.scss';

const PAGINATION_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const PAGINATION = {
  page: 1,
  perPage: 5,
};

function OrderTypes() {
  const navigate = useNavigate();

  const orderTypesStatus = useSelector((state) => state.orderTypes.status);
  const orders = useSelector((state) => state.orderTypes.orderTypes);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(orders, PAGINATION.page, PAGINATION.perPage);

  useEffect(() => {
    if (token) {
      dispatch(fetchOrderTypes({ token }));
    }
  }, [dispatch, token]);

  const handleToggleOrderActive = useCallback(
    (id, value) => {
      const updatedDetails = {
        ...currentPageItems.find((order) => order.id === id),
        is_active: value,
      };
      dispatch(updateExistingOrderType({ id, updatedDetails, token }));
    },
    [dispatch, token, currentPageItems],
  );

  const handleAddNewOrderType = () => {
    navigate(`/business/order_types/new`);
  };

  const handleEditOrderType = (id) => {
    navigate(`/business/order_types/${id}/edit`);
  };

  const handleDeleteOrderType = (id) => {
    dispatch(removeOrderType({ id, token }));
  };

  return (
    <>
      <PageTitle name={'Order Types'} />
      <div className={styles.list_controller}>
        <div>
          <Button title={'Add New Order Type'} onClick={handleAddNewOrderType} />
        </div>
      </div>
      <OrderTypeList
        orders={currentPageItems}
        onEdit={handleEditOrderType}
        onDelete={handleDeleteOrderType}
        onToggleActive={handleToggleOrderActive}
        isLoading={orderTypesStatus === 'loading'}
      />
      <Pagination
        pageSizeOptions={PAGINATION_OPTIONS}
        numberOfItems={orders.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </>
  );
}

export default OrderTypes;
