import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getItems,
  addItem,
  getItem,
  updateItem,
  deleteItem,
  updateItemStatusPOS,
  updateItemStatusWebsite,
} from "../api/menu_items";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchMenuItems = createAsyncThunk(
  "menu_items/fetchMenuItems",
  async (token) => {
    const response = await getItems(token);
    return response.data;
  }
);

export const createMenuItem = createAsyncThunk(
  "menu_items/createMenuItem",
  async ({ newDetails, token }, { dispatch }) => {
    try {
      const response = await addItem(newDetails, token);
      dispatch(
        addNotification({
          message: "Item created successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to create item", status: "failed" })
      );
      throw error;
    }
  }
);

export const fetchMenuItem = createAsyncThunk(
  "menu_items/fetchMenuItem",
  async ({ id, token }) => {
    const response = await getItem(id, token);
    return response.data;
  }
);

export const updateExistingMenuItem = createAsyncThunk(
  "menu_items/updateExistingMenuItem",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateItem(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Item updated successfully",
          status: "succeeded",
        })
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update item", status: "failed" })
      );
      throw error;
    }
  }
);

export const removeMenuItem = createAsyncThunk(
  "menu_items/removeMenuItem",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteItem(id, token);
      dispatch(
        addNotification({
          message: "Item deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to delete item", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingMenuItemStatusPOS = createAsyncThunk(
  "menu_items/updateExistingMenuItemStatusPOS",
  async ({ id, status, token }, { dispatch }) => {
    console.log("updateExistingMenuItemStatusPOS id: ", id);
    console.log("updateExistingMenuItemStatusPOS status:", status);
    console.log("updateExistingMenuItemStatusPOS: token", token);

    try {
      await updateItemStatusPOS(id, status, token);
      dispatch(
        addNotification({
          message: "Item status on POS was changed successfully",
          status: "succeeded",
        })
      );
      return { id, status };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to change item status on POS",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateExistingMenuItemStatusWebsite = createAsyncThunk(
  "menu_items/updateExistingMenuItemStatusWebsite",
  async ({ id, status, token }, { dispatch }) => {
    try {
      await updateItemStatusWebsite(id, status, token);
      dispatch(
        addNotification({
          message: "Item status on Website was changed successfully",
          status: "succeeded",
        })
      );
      return { id, status };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to change item status on Website",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

const initialState = {
  menu_items: [],
  menu_item: {},
  status: "idle",
  error: null,
};

const menuItemsSlice = createSlice({
  name: "menu_items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchMenuItems.pending, setLoading)
      .addCase(fetchMenuItems.fulfilled, (state, action) => {
        setSucceeded(state);
        state.menu_items = action.payload;
      })
      .addCase(fetchMenuItems.rejected, setFailed)

      .addCase(createMenuItem.pending, setLoading)
      .addCase(createMenuItem.fulfilled, (state, action) => {
        setSucceeded(state);
        state.menu_items.push(action.payload);
      })
      .addCase(createMenuItem.rejected, setFailed)

      .addCase(fetchMenuItem.pending, setLoading)
      .addCase(fetchMenuItem.fulfilled, (state, action) => {
        setSucceeded(state);
        state.menu_item = action.payload;
      })
      .addCase(fetchMenuItem.rejected, setFailed)

      .addCase(updateExistingMenuItem.pending, setLoading)
      .addCase(updateExistingMenuItem.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingMenuItem = state.menu_items.find(
          (item) => item.id === id
        );

        if (existingMenuItem) {
          Object.assign(existingMenuItem, updatedDetails);
        }
      })
      .addCase(updateExistingMenuItem.rejected, setFailed)

      .addCase(removeMenuItem.pending, setLoading)
      .addCase(removeMenuItem.fulfilled, (state, action) => {
        setSucceeded(state);
        state.menu_items = state.menu_items.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(removeMenuItem.rejected, setFailed)

      .addCase(updateExistingMenuItemStatusPOS.pending, setLoading)
      .addCase(updateExistingMenuItemStatusPOS.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, status } = action.payload;
        const existingMenuItem = state.menu_items.find(
          (item) => item.id === id
        );
        if (existingMenuItem) {
          existingMenuItem.hide_status = status;
        }
      })
      .addCase(updateExistingMenuItemStatusPOS.rejected, setFailed)

      .addCase(updateExistingMenuItemStatusWebsite.pending, setLoading)
      .addCase(updateExistingMenuItemStatusWebsite.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, status } = action.payload;
        const existingMenuItem = state.menu_items.find(
          (item) => item.id === id
        );
        if (existingMenuItem) {
          existingMenuItem.web_site_hide_status = status;
        }
      })
      .addCase(updateExistingMenuItemStatusWebsite.rejected, setFailed);
  },
});

export default menuItemsSlice.reducer;
