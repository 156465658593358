import styles from './backdrop-loading.module.scss';
import Spinner from '../spinner/spinner';

const BackdropLoading = () => {
  return (
    <div className={styles.backdrop}>
      <Spinner />
    </div>
  );
};

export default BackdropLoading;
