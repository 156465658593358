import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import styles from "./edit-timesheet-details.module.scss";

const parseDate = (dateString) => {
  if (!dateString || dateString === "-" || isNaN(Date.parse(dateString))) {
    return null;
  }
  return new Date(dateString);
};

const adjustForTimeZone = (date) => {
  const offsetInMinutes = date.getTimezoneOffset();
  return new Date(date.getTime() - offsetInMinutes * 60000);
};

const formatTime = (time) => {
  if (!time || time === "-") return "00:00:00";
  try {
    const parsedTime = new Date(`1970-01-01T${time}`);
    return format(parsedTime, "HH:mm:ss");
  } catch (error) {
    console.error("Invalid time format:", time);
    return "00:00:00";
  }
};

function EditTimesheetDetails() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const timesheet_details_records = useSelector(
    (state) => state.timesheet.timesheet_details_records
  );

  const [employeeName, setEmployeeName] = useState("");
  const [recordId, setRecordId] = useState("");

  const [selectedClockInISO, setSelectedClockInISO] = useState(null);
  const [selectedClockIn, setSelectedClockIn] = useState(null);
  const [selectedClockOutISO, setSelectedClockOutISO] = useState(null);
  const [selectedClockOut, setSelectedClockOut] = useState(null);

  //const [selectedClockInDate, setSelectedClockInDate] = useState(null);
  //const [selectedClockOutDate, setSelectedClockOutDate] = useState(null);
  //const [selectedClockInTime, setSelectedClockInTime] = useState("");
  //const [selectedClockOutTime, setSelectedClockOutTime] = useState("");

  useEffect(() => {
    console.log("timesheet_details_records", timesheet_details_records);
    console.log("recordId", recordId);

    if (!timesheet_details_records) return;

    const record = timesheet_details_records.find(
      (record) => record.id === recordId
    );


    console.log("record", record);

    if (record) {
      if (record.clock_in !== null) {
        setSelectedClockIn(new Date(record.clock_in));
      }

      if (record.clock_out !== null) {
        setSelectedClockOut(new Date(record.clock_out));
      }
      //const parsedClockInDate = parseDate(record.date) || new Date();
      //const parsedClockOutDate = parseDate(record.clock_out_date) || new Date();

      //setSelectedClockInDate(parsedClockInDate);
      //setSelectedClockOutDate(parsedClockOutDate);

      /* setSelectedClockInTime(
        record.clock_in_time !== "-" ? record.clock_in_time : "00:00:00"
      ); */
      /* setSelectedClockOutTime(
        record.clock_out_time !== "-" ? record.clock_out_time : "00:00:00"
      ); */
    }

  }, [timesheet_details_records, recordId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmployeeName(params.get("employee_name"));
    setRecordId(Number(params.get("record_id")));
  }, [location]);

  useEffect(() => {
    if (!selectedClockIn ||!selectedClockOut) return;

    // Adjust dates before converting them to ISO format
    const adjustedClockIn = adjustForTimeZone(selectedClockIn);
    const adjustedClockOut = adjustForTimeZone(selectedClockOut);

    setSelectedClockInISO(adjustedClockIn.toISOString());
    setSelectedClockOutISO(adjustedClockOut.toISOString());

  }, [selectedClockIn, selectedClockOut]);

  const handleUpdateButtonClick = () => {
    //const formattedClockInTime = formatTime(selectedClockInTime);
    //const formattedClockOutTime = formatTime(selectedClockOutTime);
    /* const formattedClockInDay = selectedClockInDate
      ? selectedClockInDate.toISOString().split("T")[0]
      : ""; */
    /* const formattedClockOutDay = selectedClockOutDate
      ? selectedClockOutDate.toISOString().split("T")[0]
      : ""; */

    console.log("Request body: ", {
      id: recordId,
      clock_in: selectedClockInISO,
      clock_out: selectedClockOutISO,
    });
  };

  const handleBackButtonClick = () => {
    /* const formattedClockInDay = selectedClockInDate
      ? adjustForTimeZone(selectedClockInDate).toISOString().split("T")[0]
      : ""; */

    //const path = `/employee/${id}/timesheet_details?employee_name=${employeeName}&from_date=${formattedClockInDay}&to_date=${formattedClockOutDay}`;
    //navigate(path);
  };

  const handleSelectFromDate = (date) => {
    //setSelectedClockInDate(date);

    console.log("selected from date: " + date);

      setSelectedClockIn(new Date(date));
      //setSelectedClockInISO();


  };

  const handleSelectToDate = (date) => {
    //setSelectedClockOutDate(date);

    console.log("selected to date: " + date);

    setSelectedClockOut(new Date(date));
  };

  return (
    <div className={styles.edit_timesheet_details}>
      <div className={styles.back_button}>
        <button type="button" onClick={handleBackButtonClick}>
          Back to Timesheet
        </button>
      </div>
      <div className={styles.header}>
        <div className={styles.name}>{`Adjust Time: ${employeeName}`}</div>
        <div className={styles.date}>
          {/* {`Date: ${selectedClockInDate ? adjustForTimeZone(selectedClockInDate).toISOString().split("T")[0] : ""} - ${selectedClockOutDate ? adjustForTimeZone(selectedClockOutDate).toISOString().split("T")[0] : ""}`} */}
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.table_header}>
          <div className={styles.table_number}>#</div>
          <div className={styles.table_clockin}>Clock In</div>
          <div className={styles.table_clockout}>Clock Out</div>
          <div className={styles.table_status}>Status</div>
        </div>
        <div className={styles.table_body}>
          <div className={styles.table_row}>
            <div className={styles.table_number_row}></div>
            <div className={styles.table_clockin_row}>
              <DatePicker
                selected={selectedClockIn}
                onChange={(date) => handleSelectFromDate(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                className={styles.datepicker}
                placeholderText="Select a date and time"
              />
            </div>
            <div className={styles.table_clockout_row}>
              <DatePicker
                selected={selectedClockOut}
                onChange={(date) => handleSelectToDate(date)}
                showTimeSelect
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={15}
                className={styles.datepicker}
                placeholderText="Select a date and time"
              />
            </div>
            <div className={styles.table_status_row}>Clocked In</div>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <button type="button" onClick={handleUpdateButtonClick}>
          Update
        </button>
        <button type="button" onClick={handleBackButtonClick}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default EditTimesheetDetails;
