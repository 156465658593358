import { api, handleApiResponse } from "./base";


export const getTaxes = (token) =>
  handleApiResponse(() =>     
    api.get("taxes", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addNewTax = (newDetails, token) =>
  handleApiResponse(() => api.post("taxes", newDetails, {
    headers: {
      TOKEN: token,
    },
  })
);

export const updateTax = (id, updatedDetails, token) =>
  handleApiResponse(() => api.put(`taxes/${id}`, updatedDetails, {
    headers: {
      TOKEN: token,
    },
  })
);

export const deleteTax = (id, token) =>
  handleApiResponse(() => api.delete(`taxes/${id}`, {
    headers: {
      TOKEN: token,
    },
  })
);

export const updateTaxStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(`taxes/${id}/active?is_active=${is_active}`, {
      headers: {
        TOKEN: token,
      },
    })
  );
