import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { addNewEmployee } from '../../../../redux/employeesSlice';
import { fetchTeamRoles } from '../../../../redux/rolesSlice';

import { IoIosArrowDown } from 'react-icons/io';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Selector from '../../../utilities/selector/selector';
import Button from '../../../../components/utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import { registrationSchema } from './validation';

import styles from './employees.module.scss';

const valueConverter = (value, type) => {
  return type === 'number' ? parseFloat(value) : type === 'boolean' ? !!value : value.toString();
};

const AddEmployee = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const employeesStatus = useSelector((state) => state.employees.status);
  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const rolesOptionsStatus = useSelector((state) => state.roles.status);
  const [employee, setEmployee] = useState({});

  const [errors, setErrors] = useState({});

  const statusLoading = employeesStatus === 'loading';

  useEffect(() => {
    if (rolesOptionsStatus === 'idle') {
      dispatch(fetchTeamRoles(token));
    }
  }, [dispatch, token, employeesStatus, rolesOptionsStatus]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setEmployee((prev) => ({
      ...prev,
      [name]: valueConverter(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/employee/employees');
  };

  const handleRoleChange = (value) => {
    handleInputChange({ target: { name: 'team_role_id', value: value } }, 'number');
  };

  const handleSaveChanges = async () => {
    const result = registrationSchema.safeParse(employee);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      setErrors({});

      try {
        await dispatch(addNewEmployee({ newEmployeeDetails: employee, token })).unwrap();
        navigate('/employee/employees');
      } catch {}
    }
  };

  return (
    <section className={styles.employee_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Add Employee'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.field}>
              <Input
                label={'First name'}
                value={employee.first_name}
                onChange={handleInputChange}
                name={'first_name'}
                required={true}
                errors={errors.first_name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Last name'}
                value={employee.last_name}
                onChange={handleInputChange}
                name={'last_name'}
                required={true}
                errors={errors.last_name?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Email address'}
                type="email"
                value={employee.email}
                onChange={handleInputChange}
                name={'email'}
                errors={errors.email?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Mobile number'}
                type="text"
                value={employee.phone_number}
                onChange={handleInputChange}
                name={'phone_number'}
                errors={errors.phone_number?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Passcode'}
                type="text"
                value={employee.passcode}
                onChange={handleInputChange}
                name={'passcode'}
                required={true}
                errors={errors.passcode?._errors}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Hourly Rate'}
                type="number"
                value={employee.hourly_wages}
                onChange={handleInputChange}
                name={'hourly_wages'}
                errors={errors.hourly_wages?._errors}
              />
            </div>
            <div className={styles.field}>
              <Selector
                icon={IoIosArrowDown}
                label={'Role'}
                items={rolesOptions}
                selectedItem={employee.team_role_id}
                onSelectItem={handleRoleChange}
                placeholder={'Select Role'}
                required={true}
                errors={errors.team_role_id?._errors}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Add employee'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEmployee;
