import React from "react";
import { RxCross2 } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/context";

import Modal from "../../../../components/utilities/modal/modal";

import { updateExistingDiscount } from "../../../../redux/discountsSlice";

import styles from "./discounts-edit-modal.module.scss";

function EditSalesTaxesModal({
  isModalOpen,
  setIsModalOpen,
  editableItem,
  setEditableItem,
  editableName,
  editableRate,
  setEditableName,
  setEditableRate,
}) {
  const dispatch = useDispatch();
  const { token } = useAppContext();
  const { id, discount_type, location_id } = editableItem;

  const handleSave = () => {
    dispatch(
      updateExistingDiscount({
        id: id,
        updatedDetails: {
          id: id,
          location_id: location_id,
          name: editableName,
          percentage: editableRate,
          discount_type: discount_type,
        },
        token,
      })
    );
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditableItem({});
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      className={styles.edit_discount_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>Edit Discounts</h3>
          <div className={styles.cross_icon} onClick={handleCloseModal}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.edit_discount_wrapper}>
            <label>
              Discount Name<span>*</span>
            </label>
            <input
              type="text"
              required
              value={editableName}
              placeholder="New name"
              onChange={(e) => setEditableName(e.target.value)}
              style={{ color: "#959A9E", marginBottom: "20px" }}
            />
          </div>

          <div className={styles.edit_discount_wrapper}>
            <label>
              Discount Rate<span>*</span>
            </label>
            <input
              type="number"
              required
              value={editableRate}
              placeholder="New rate"
              onChange={(e) => setEditableRate(Number(e.target.value))}
              style={{ color: "#959A9E" }}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.update_button}
            onClick={handleSave}
          >
            Update
          </button>
          <button
            type="button"
            className={styles.cancel_button}
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditSalesTaxesModal;
