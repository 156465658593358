import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import {
  removeCancelOrdersReason,
  updateExistingCancelOrdersReason,
  addCancelOrdersReason,
  reorderCancelOrdersReason,
  setQueryDetails,
} from '../../../../../redux/cancelOrderReasonsSlice';

import { useAppContext } from '../../../../../context/context';
import { valueConverter } from '../../../../utilities/utils';

const useCancelReasonsManagement = (cancelReasons) => {
  const [cancelReasonsState, setCancelReasonsState] = useState(cancelReasons);
  const [initialCancelReasonsState, setInitialCancelReasonsState] = useState(cancelReasons);
  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    setCancelReasonsState(cancelReasons);
    setInitialCancelReasonsState(cancelReasons);
  }, [cancelReasons]);

  const handleSetSearch = (result = '') => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleAddReason = useCallback(
    (newReasonName) => {
      dispatch(addCancelOrdersReason({ newCancelReasonDetails: { reason: newReasonName }, token }));
    },
    [dispatch, token],
  );

  const handleToggleReasonActive = useCallback((id, value) => {
    setCancelReasonsState((prev) => {
      return prev.map((reason) => {
        if (reason.id === parseFloat(id)) {
          return { ...reason, is_active: value };
        }

        return reason;
      });
    });
  }, []);

  const handleconfirmAndDeleteReason = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-cancel-reason',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this cancel order reason',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeCancelOrdersReason({ id, token }));
                  dispatch(hideModal('modal-delete-cancel-reason'));
                },
              },
              {
                title: 'Cancel',
                button_variant: 'grey',
                onAction: () => dispatch(hideModal('modal-delete-cancel-reason')),
              },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleSaveChanges = useCallback(() => {
    const updatedReasons = cancelReasonsState.filter(
      (item, index) => JSON.stringify(initialCancelReasonsState[index]) !== JSON.stringify(cancelReasonsState[index]),
    );

    Promise.allSettled(
      updatedReasons.map((item) =>
        dispatch(updateExistingCancelOrdersReason({ id: item.id, updatedDetails: item, token })),
      ),
    );
  }, [dispatch, initialCancelReasonsState, token, cancelReasonsState]);

  const handleUpdateReasonName = useCallback((e, type) => {
    const { id, value } = e.target;

    setCancelReasonsState((prev) => {
      return prev.map((reason) => {
        if (reason.id === parseFloat(id)) {
          console.log({ ...reason, reason: valueConverter(value, type) });
          return { ...reason, reason: valueConverter(value, type) };
        }

        return reason;
      });
    });
  }, []);

  const handleChangeList = useCallback((state) => {
    setCancelReasonsState(state);
  }, []);

  const handleReorderReasons = (item) => {
    const old_position = cancelReasonsState.find((modifier) => modifier.id === item.id)?.sort;
    const new_position = cancelReasonsState[item.index]?.sort;
    console.log(item, cancelReasonsState);
    dispatch(
      reorderCancelOrdersReason({ id: item.id, updatedDetails: { id: item.id, old_position, new_position }, token }),
    );
  };

  return {
    cancelReasonsState,
    initialCancelReasonsState,
    handleAddReason,
    handleToggleReasonActive,
    handleconfirmAndDeleteReason,
    handleUpdateReasonName,
    handleChangeList,
    handleSetSearch,
    handleSaveChanges,
    handleReorderReasons,
  };
};

export default useCancelReasonsManagement;
