import React from "react";

import List from "../../../utilities/list/list";
import Switch from "../../../utilities/switch/switch";

import { IoTrash } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";

import styles from "./discount-list.module.scss";

const DiscountsList = ({
  items,
  onEdit,
  onDelete,
  onToggleActive,
  isLoading,
}) => {
  const renderDragAndDropIcon = (item) => (
    <div className={styles.drag_icon}>
      <GiHamburgerMenu />
    </div>
  );

  const renderInputName = (item) => <div>{item.name}</div>;

  const renderInputRate = (item) => <div>{`${item.percentage}%`}</div>;

  const renderSwitcher = (item) => (
    <Switch
      id={item.id}
      roleName={"User"}
      isActive={item.is_active}
      onSwitch={onToggleActive}
    />
  );

  const columnConfig = [
    {
      key: "sort",
      header: "",
      render: renderDragAndDropIcon,
    },
    {
      key: "name",
      header: "Discount Name",
      render: renderInputName,
    },
    {
      key: "percentage",
      header: "Discount Rate",
      render: renderInputRate,
    },
    {
      key: "is_active",
      header: "Status",
      render: renderSwitcher,
    },
  ];

  const rowActions = [
    {
      icon: <MdModeEditOutline />,
      onClick: onEdit,
      label: "Edit discount",
    },
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: "Delete discount",
    },
  ];

  return (
    <List
      //isDragable={true}
      data={items}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
    />
  );
};

export default DiscountsList;
