export const mergeById = (stateArray = [], newArray = [], idKey = 'id') => {
  if (!Array.isArray(stateArray)) stateArray = [];
  if (!Array.isArray(newArray)) newArray = [];

  if (newArray.length === 0) return [];

  const updatedArray = stateArray.map((item) => {
    if (!item || typeof item[idKey] === 'undefined') return item;

    const newItem = newArray.find((newItem) => newItem && newItem[idKey] === item[idKey]);
    return newItem ? { ...item, ...newItem } : item;
  });

  const newUniqueItems = newArray.filter(
    (newItem) => newItem && !stateArray.some((item) => item && item[idKey] === newItem[idKey]),
  );

  return [...updatedArray, ...newUniqueItems];
};

export const isObjectEmpty = (objectName) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};
