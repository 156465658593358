import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTaxes,
  addNewTax,
  updateTax,
  deleteTax,
  updateTaxStatus,
} from "../api/taxes";

import { addNotification } from "./tooltipSlice";

export const fetchTaxes = createAsyncThunk("taxes/fetchTaxes", async (token) => {
  const response = await getTaxes(token);
  return response.data;
});

export const addTax = createAsyncThunk(
  "taxes/addTax",
  async ({ newDetails, token }, { dispatch }) => {
    try {
      const response = await addNewTax(newDetails, token);
      dispatch(
        addNotification({
          message: "Tax created successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to create tax", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingTax = createAsyncThunk(
  "taxes/updateExistingTax",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateTax(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Tax was updated successfully",
          status: "succeeded",
        })
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update tax", status: "failed" })
      );
      throw error;
    }
  }
);

export const removeTax = createAsyncThunk(
  "taxes/removeTax",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteTax(id, token);
      dispatch(
        addNotification({
          message: "Tax deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to delete tax", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingTaxStatus = createAsyncThunk(
  "taxes/updateExistingTaxStatus",
  async ({ id, is_active, token }, { dispatch }) => {
    try {
      await updateTaxStatus(id, is_active, token);
      dispatch(
        addNotification({
          message: "Tax status was updated successfully",
          status: "succeeded",
        })
      );
      return { id, is_active };
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update tax status", status: "failed" })
      );
      throw error;
    }
  }
);

const initialState = {
  taxes: [],
  status: "idle",
  error: null,
};

const taxesSlice = createSlice({
  name: "taxes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchTaxes.pending, setLoading)
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        setSucceeded(state);
        state.taxes = action.payload;
      })
      .addCase(fetchTaxes.rejected, setFailed)

      .addCase(removeTax.fulfilled, (state, action) => {
        state.taxes = state.taxes.filter((tax) => tax.id !== action.payload);
      })

      .addCase(updateExistingTax.pending, setLoading)
      .addCase(updateExistingTax.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingTax = state.taxes.find((tax) => tax.id === id);
        if (existingTax) {
          Object.assign(existingTax, updatedDetails);
        }
      })
      .addCase(updateExistingTax.rejected, setFailed)

      .addCase(addTax.pending, setLoading)
      .addCase(addTax.fulfilled, (state, action) => {
        setSucceeded(state);
        state.taxes.push(action.payload);
      })
      .addCase(addTax.rejected, setFailed)

      .addCase(updateExistingTaxStatus.pending, setLoading)
      .addCase(updateExistingTaxStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, is_active } = action.payload;
        const existingTax = state.taxes.find((tax) => tax.id === id);

        if (existingTax) {
          existingTax.is_active = is_active;
        }
      })
      .addCase(updateExistingTaxStatus.rejected, setFailed);
  },
});

export default taxesSlice.reducer;
