import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { z } from 'zod';

import Input from '../../utilities/input/input';
import Checkbox from '../../utilities/checkbox/checkbox';
import Button from '../../utilities/button/button';
import Selector from '../../utilities/selector/selector';

import { showModal, hideModal } from '../../../redux/modalSlice';

import moment from 'moment-timezone';

import Logo from '../../../assets/img/snack-logo.svg';

import Modal from '../../utilities/modal/modal-template';

import clsx from 'clsx';
import styles from './business-info.module.scss';

const registrationSchema = z.object({
  businessName: z.string().min(1, 'Business name is required'),
  businessPhoneAddress: z.string().min(1, 'Business phone address is required'),
  businessEmail: z.string().min(1, 'Email is required').email('Invalid email format'),
  businessType: z.string().min(1, 'Business type is required'),
  timeZone: z.string().min(1, 'Time zone is required'),
  businessAddress: z.object({
    streetAddress: z.string().min(1, 'Street address is required'),
    state: z.string().min(1, 'State is required'),
    city: z.string().min(1, 'City is required'),
    country: z.string().min(1, 'Country is required'),
    zipCode: z.string().min(1, 'Zip code is required'),
  }),
});

const businessType = [
  { value: 1, label: 'One' },
  { value: 2, label: 'Two' },
];

const timezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).format('Z');
  return {
    label: `(GMT${offset}) ${timezone}`,
    value: `(GMT${offset}) ${timezone}`,
  };
});

const BusinessInfo = () => {
  const [formState, setFormState] = useState({
    businessName: '',
    businessPhoneAddress: '',
    businessEmail: '',
    businessType: '',
    timeZone: '',
    businessAddress: {
      streetAddress: '',
      state: '',
      city: '',
      country: '',
      zipCode: '',
    },
  });

  const [errors, setErrors] = useState({});
  const [sameAddress, setSameAddress] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isNavigatedProgrammatically = location.state?.fromNavigation;

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = registrationSchema.safeParse(formState);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      const dataResult = {
        ...formState,
        ...(sameAddress &&
          formState.businessAddress && {
            homeAddress: formState.businessAddress,
          }),
      };
      console.log(dataResult);
      setErrors({});
    }
  };

  const handleEndSession = useCallback(() => {
    dispatch(
      showModal({
        modalId: 'modal-end-session',
        data: {
          type: 'confirmation',
          title: 'End Session',
          message: 'Are you sure, you want to cancel current session',
          actions: [
            {
              title: 'Ok',
              onAction: () => {
                navigate('/login');
                dispatch(hideModal('modal-end-session'));
              },
            },
            { title: 'Cancel', button_variant: 'grey', onAction: () => dispatch(hideModal('modal-end-session')) },
          ],
        },
      }),
    );
  }, [dispatch, navigate]);

  const handleMainFormChange = useCallback((e) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleBusinessAddressFormChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      businessAddress: {
        ...prev.businessAddress,
        [name]: value,
      },
    }));
  }, []);

  const handleHomeAddressFormChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      homeAddress: {
        ...prev.homeAddress,
        [name]: value,
      },
    }));
  }, []);

  if (!isNavigatedProgrammatically) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <img className={styles.logo} src={Logo} draggable={false} alt="Snack logo" />
        </div>
        <div className={styles.content}>
          <h1>Tell us about your business.</h1>
          <div className={styles.terms_info}>
            <h4>Please provide your restaurant information here</h4>
            <h4>so we can best setup your account.</h4>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <Input
                value={formState.businessName}
                id="businessName"
                name="businessName"
                type="text"
                autoComplete="businessName"
                onChange={handleMainFormChange}
                activePlaceholder={true}
                placeholder={'Business Name'}
                required={true}
                errors={errors.businessName?._errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Input
                value={formState.businessPhoneAddress}
                id="businessPhoneAddress"
                name="businessPhoneAddress"
                type="text"
                autoComplete="businessPhoneAddress"
                onChange={handleMainFormChange}
                activePlaceholder={true}
                placeholder={'Business Phone Address'}
                required={true}
                errors={errors.businessPhoneAddress?._errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Input
                value={formState.businessEmail}
                id="businessEmail"
                name="businessEmail"
                type="email"
                autoComplete="businessEmail"
                onChange={handleMainFormChange}
                activePlaceholder={true}
                placeholder={'Business Email Address'}
                required={true}
                errors={errors.businessEmail?._errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Input
                value={formState.businessWebsite}
                id="businessWebsite"
                name="businessWebsite"
                type={'text'}
                autoComplete="businessWebsite"
                onChange={handleMainFormChange}
                activePlaceholder={true}
                placeholder={'Business Website'}
              />
            </div>
            <div className={styles.formGroup}>
              <Selector
                selectedItem={formState.businessType}
                placeholder="Business Type"
                activePlaceholder={true}
                items={businessType}
                onSelectItem={(value) => handleMainFormChange({ target: { name: 'businessType', value } })}
                required={true}
                errors={errors.businessType?._errors}
              />
            </div>
            <div className={styles.formGroup}>
              <Selector
                selectedItem={formState.timeZone}
                placeholder="Time Zone"
                activePlaceholder={true}
                items={timezones}
                onSelectItem={(value) => handleMainFormChange({ target: { name: 'timeZone', value } })}
                required={true}
                errors={errors.timeZone?._errors}
              />
            </div>
            <div className={styles.business_address}>
              <h3>Business Address</h3>
              <div className={styles.formGroup}>
                <Input
                  value={formState.businessAddress?.streetAddress}
                  id="streetAddress"
                  name="streetAddress"
                  type="text"
                  autoComplete="streetAddress"
                  onChange={handleBusinessAddressFormChange}
                  activePlaceholder={true}
                  placeholder={'Street Address'}
                  required={true}
                  errors={errors.businessAddress?.streetAddress?._errors}
                />
              </div>
              <div className={styles.formGroup}>
                <Input
                  value={formState.businessAddress?.aptSuiteEtc}
                  id="aptSuiteEtc"
                  name="aptSuiteEtc"
                  type="text"
                  autoComplete="aptSuiteEtc"
                  onChange={handleBusinessAddressFormChange}
                  activePlaceholder={true}
                  placeholder={'Apt, Suite, Etc. (Optional)'}
                  errors={errors.businessAddress?.aptSuiteEtc?._errors}
                />
              </div>
              <div className={clsx(styles.row, styles.formGroup)}>
                <Input
                  value={formState.businessAddress?.state}
                  id="state"
                  name="state"
                  type="text"
                  autoComplete="state"
                  onChange={handleBusinessAddressFormChange}
                  activePlaceholder={true}
                  placeholder={'State'}
                  required={true}
                  errors={errors.businessAddress?.state?._errors}
                />
                <Input
                  value={formState.businessAddress?.city}
                  id="city"
                  name="city"
                  type="text"
                  autoComplete="city"
                  onChange={handleBusinessAddressFormChange}
                  activePlaceholder={true}
                  placeholder={'City'}
                  required={true}
                  errors={errors.businessAddress?.city?._errors}
                />
              </div>
              <div className={clsx(styles.row, styles.formGroup)}>
                <Input
                  value={formState.businessAddress?.country}
                  id="country"
                  name="country"
                  type="text"
                  autoComplete="country"
                  onChange={handleBusinessAddressFormChange}
                  activePlaceholder={true}
                  placeholder={'Country'}
                  required={true}
                  errors={errors.businessAddress?.country?._errors}
                />
                <Input
                  value={formState.businessAddress?.zipCode}
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  autoComplete="zipCode"
                  onChange={handleBusinessAddressFormChange}
                  activePlaceholder={true}
                  placeholder={'Zip Code'}
                  required={true}
                  errors={errors.businessAddress?.zipCode?._errors}
                />
              </div>
            </div>
            <div className={styles.i_agree}>
              <div className={styles.checkbox}>
                <Checkbox isChecked={sameAddress} handleOnChange={() => setSameAddress((prev) => !prev)} />
                <p>My business address & Home address are the same</p>
              </div>
              {errors.iAgree?._errors && <div className={styles.error}>{errors.iAgree._errors[0]}</div>}
            </div>
            {!sameAddress && (
              <div className={styles.business_address}>
                <h3>Home Address</h3>
                <div className={styles.formGroup}>
                  <Input
                    value={formState.homeAddress?.streetAddress}
                    id="streetAddress"
                    name="streetAddress"
                    type="text"
                    autoComplete="streetAddress"
                    onChange={handleHomeAddressFormChange}
                    activePlaceholder={true}
                    placeholder={'Street Address'}
                    errors={errors.homeAddress?.streetAddress?._errors}
                  />
                </div>
                <div className={styles.formGroup}>
                  <Input
                    value={formState.homeAddress?.aptSuiteEtc}
                    id="aptSuiteEtc"
                    name="aptSuiteEtc"
                    type="text"
                    autoComplete="aptSuiteEtc"
                    onChange={handleHomeAddressFormChange}
                    activePlaceholder={true}
                    placeholder={'Apt, Suite, Etc. (Optional)'}
                  />
                </div>
                <div className={clsx(styles.row, styles.formGroup)}>
                  <Input
                    value={formState.homeAddress?.state}
                    id="state"
                    name="state"
                    type="text"
                    autoComplete="state"
                    onChange={handleHomeAddressFormChange}
                    activePlaceholder={true}
                    placeholder={'State'}
                    errors={errors.homeAddress?.state?._errors}
                  />
                  <Input
                    value={formState.homeAddress?.city}
                    id="city"
                    name="city"
                    type="text"
                    autoComplete="city"
                    onChange={handleHomeAddressFormChange}
                    activePlaceholder={true}
                    placeholder={'City'}
                    errors={errors.homeAddress?.city?._errors}
                  />
                </div>
                <div className={clsx(styles.row, styles.formGroup)}>
                  <Input
                    value={formState.homeAddress?.country}
                    id="country"
                    name="country"
                    type="text"
                    autoComplete="country"
                    onChange={handleHomeAddressFormChange}
                    activePlaceholder={true}
                    placeholder={'Country'}
                    errors={errors.homeAddress?.country?._errors}
                  />
                  <Input
                    value={formState.homeAddress?.zipCode}
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    autoComplete="zipCode"
                    onChange={handleHomeAddressFormChange}
                    activePlaceholder={true}
                    placeholder={'Zip Code'}
                    errors={errors.homeAddress?.zipCode?._errors}
                  />
                </div>
              </div>
            )}
            <Button
              title="Continue"
              type="submit"
              className={styles.submit_button}
              style={{ width: '100%', fontSize: '1rem' }}
            />
            <div className={styles.end_session} onClick={handleEndSession}>
              <p>End session</p>
            </div>
          </form>
        </div>
      </div>
      <Modal />
    </>
  );
};

export default BusinessInfo;
