import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchTerminalSettings } from '../../../../redux/terminalSettingsSlice';
import { useAppContext } from '../../../../context/context';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Button from '../../../../components/utilities/button/button';
import TerminalSettingsList from './terminal-settings-list';

import useTerminalSettingsManagement from './hooks/useTerminalSettingsManagement';

import styles from './terminal-settings.module.scss';

const TerminalSettings = () => {
  const terminalSettingsStatus = useSelector((state) => state.terminalSettings.status);
  const terminalSettings = useSelector((state) => state.terminalSettings.terminalSettings);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    settingsState,
    initialsettingsState,
    handleAddSetting,
    handleToggleSettingActive,
    handleconfirmAndDeleteReason,
    handleUpdateSetting,
    handleChangeList,
    handleSetSearch,
    handleSaveChanges,
  } = useTerminalSettingsManagement(terminalSettings);

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(settingsState) === JSON.stringify(initialsettingsState),
    [initialsettingsState, settingsState],
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchTerminalSettings({ token }));
    }
  }, [dispatch, token]);
  console.log(settingsState);
  return (
    <>
      <PageTitle name={'Terminal'} />
      <TerminalSettingsList
        settings={settingsState}
        onInputChange={handleUpdateSetting}
        onListChange={handleChangeList}
        onDelete={handleconfirmAndDeleteReason}
        onToggleActive={handleToggleSettingActive}
        isLoading={terminalSettingsStatus === 'loading'}
        isFailed={terminalSettingsStatus === 'failed'}
      />
      {!hasUnsavedChanges && (
        <div className={styles.action_buttons}>
          <Button className={styles.saveButton} title={'Update'} onClick={handleSaveChanges} />
        </div>
      )}
    </>
  );
};

export default TerminalSettings;
