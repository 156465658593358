import { useState } from 'react';

import Input from '../../../../utilities/input/input';
import Button from '../../../../utilities/button/button';

import styles from '../wastage-reasons.module.scss';

const WastageForm = ({ onAdd }) => {
  const [reasonName, setReasonName] = useState('');

  const handleSubmit = () => {
    if (!reasonName.trim()) return;
    onAdd(reasonName);
    setReasonName('');
  };

  return (
    <div className={styles.list_controller}>
      <div className={styles.add_wastage}>
        <Input
          label="Wastage Reason Name"
          placeholder="Add Wastage Reason Name"
          value={reasonName}
          onChange={(e) => setReasonName(e.target.value)}
          style={{ marginBottom: 0, width: '100%' }}
        />
      </div>
      <Button title="Add Wastage" onClick={handleSubmit} style={{ marginBottom: 0 }} />
    </div>
  );
};

export default WastageForm;
