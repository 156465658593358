import { api } from './base';

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || 'API request failed';
  }
};

export const getEmployees = (token, queryDetails = {}) => {
  const { search, page, perPage, team_role_id } = queryDetails;
  const params = new URLSearchParams();

  if (search) params.append('search', search);
  if (page) params.append('page', page.toString());
  if (perPage) params.append('per_page', perPage.toString());
  if (team_role_id) params.append('team_role_id', parseFloat(team_role_id));

  const queryString = params.toString();
  const url = `employees${queryString ? `?${queryString}` : ''}`;

  return handleApiResponse(() =>
    api.get(url, {
      headers: {
        TOKEN: token,
      },
    }),
  );
};

export const getEmployee = (id, token) =>
  handleApiResponse(() =>
    api.get(`employees/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const addEmployee = (newEmployeeDetails, token) =>
  handleApiResponse(() =>
    api.post('employees', newEmployeeDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const updateEmployee = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`employees/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    }),
  );

export const deleteEmployee = (id, token) =>
  handleApiResponse(() =>
    api.delete(`employees/${id}`, {
      headers: {
        TOKEN: token,
      },
    }),
  );

// export const updateServiceChargeStatus = (id, is_enabled, token) =>
//   handleApiResponse(() =>
//     api.put(`employees/${id}/active?is_enabled=${is_enabled}`, {
//       headers: {
//         TOKEN: token,
//       },
//     })
//   );
