import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

import { resetPassword } from "../../../api/auth";

import Modal from "../../utilities/modal/modal";

import Logo from "../../../assets/img/crunch-logo-256px.png";
import styles from "./reset-password.module.scss";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setEmail(params.get("email"));
    setToken(params.get("token"));
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const lengthCheck = newPassword.length >= 6;
    const charCheck = /^[a-zA-Z0-9]+$/.test(newPassword);

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      setIsModalOpen(true);
      setIsSuccessful(false);
      return;
    }

    if (!lengthCheck) {
      setMessage("Password must be at least 6 characters long.");
      setIsModalOpen(true);
      return;
    }

    if (!charCheck) {
      setMessage(
        "Password must contain only English letters and Arabic numbers."
      );
      setIsModalOpen(true);
      return;
    }

    const hashedPassword = CryptoJS.SHA256(newPassword).toString(CryptoJS.enc.Base64);

    try {
      const response = await resetPassword(email, hashedPassword, token);
      console.log("Reset password passed successfully:", response);

      setMessage(response.message || "Password reset successfully.");
      setIsModalOpen(true);
      setIsSuccessful(true);
    } catch (error) {
      setMessage("Error resetting password.");
      setIsModalOpen(true);
      setIsSuccessful(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (isSuccessful) {
      navigate("/login");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.reset_password}>
        <img className={styles.logo} src={Logo} alt="Snack POC logo" />
        <span className={styles.title}>Serving efficiency</span>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            className={styles.input}
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter your new password"
          />
          <input
            className={styles.input}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm your new password"
          />
          <button className={styles.submit_button} type="submit">
            Reset Password
          </button>
        </form>
        <div className={styles.return_login}>
          <Link to="/login">Return to authentication</Link>
        </div>
      </div>

      <Modal
        message={message}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
}

export default ResetPassword;
