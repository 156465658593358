import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { useAppContext } from "../../../../context/context";

import {
  updateExistingTeamRole,
  removeTeamRole,
} from "../../../../redux/rolesSlice";

import Modal from "../../../utilities/modal/modal";

import { IoTrashOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";

import clsx from "clsx";
import styles from "./team-roles.module.scss";

const TeamRoleRow = ({
  id,
  name = "",
  employees = [],
  module_permission = [],
  all_modules,
  all_employees,
}) => {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssignEmployeesOpen, setIsAssignEmployeesOpen] = useState(false);
  const [isAssignModulesOpen, setIsAssignModulesOpen] = useState(false);
  const [isRoleHasEmployeesOpen, setIsRoleHasEmployeesOpen] = useState(false);

  const [editedRoleName, setEditedRoleName] = useState(name);

  const modalMessage =
    "Employees already use this Team Role. Please unassign the employees.";

  const getPossibleItems = (items, selectedItems) => {
    return items.map((item) => {
      const isAlreadySelected = selectedItems.some(
        (selectedItem) => selectedItem.id === item.id
      );

      return {
        ...item,
        is_already_selected: isAlreadySelected,
      };
    });
  };

  const possible_modules = getPossibleItems(all_modules, module_permission);
  const possible_employees = getPossibleItems(all_employees, employees);

  const getSelectedIds = (possibleItems) => {
    return possibleItems
      .filter((item) => item.is_already_selected)
      .map((item) => item.id);
  };

  const [selectedModules, setSelectedModules] = useState(
    getSelectedIds(possible_modules)
  );

  const [selectedEmployees, setSelectedEmployees] = useState(
    getSelectedIds(possible_employees)
  );

  const handleEdit = useCallback((id) => {
    setIsModalOpen(true);
  }, []);

  const handleAssign = useCallback((type) => {
    if (type === "employees") {
      setIsAssignEmployeesOpen(true);
    } else if (type === "modules") {
      setIsAssignModulesOpen(true);
    }
  }, []);

  const closeRoleHasEmployeesModal = () => {
    setIsRoleHasEmployeesOpen(false);
  };

  const handleCloseModal = useCallback(
    (modalType) => {
      switch (modalType) {
        case "delete":
          if (isRoleHasEmployeesOpen) {
            closeRoleHasEmployeesModal();
          }
          break;
        case "employees":
          if (isAssignEmployeesOpen) {
            setIsAssignEmployeesOpen(false);
          }
          break;
        case "modules":
          if (isAssignModulesOpen) {
            setIsAssignModulesOpen(false);
          }
          break;
        case "modal":
          if (isModalOpen) {
            setIsModalOpen(false);
          }
          break;
        default:
          console.warn(`Unknown modal type: ${modalType}`);
      }
    },
    [
      isRoleHasEmployeesOpen,
      isAssignEmployeesOpen,
      isAssignModulesOpen,
      isModalOpen,
    ]
  );

  const handleItemClick = (id, selectedIds, setSelectedIds) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((itemId) => itemId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSave = () => {
    dispatch(
      updateExistingTeamRole({
        id,
        updatedDetails: {
          id: id,
          name: editedRoleName,
          location_id: locationId,
          employee_ids: selectedEmployees,
          module_ids: selectedModules,
        },
        token,
      })
    );
    setIsModalOpen(false);
    setIsAssignEmployeesOpen(false);
    setIsAssignModulesOpen(false);
  };

  const handleDelete = useCallback(
    (id) => {
      if (employees.length > 0) {
        setIsRoleHasEmployeesOpen(true);
      } else {
        dispatch(removeTeamRole({ id, token }));
      }
    },
    [dispatch, token, employees.length]
  );

  return (
    <>
      <div className={styles.table_row} key={id}>
        <div className={styles.role_name}>
          <span>{name}</span>
        </div>
        <div className={styles.employees_list}>
          {employees
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((employee) => employee.name)
            .join(", ")}
        </div>
        <div className={styles.assign_employee_row}>
          <span onClick={() => handleAssign("employees")}>Assign Employee</span>
        </div>
        <div className={styles.assign_module_row}>
          <span onClick={() => handleAssign("modules")}>Assign Module</span>
        </div>
        <div className={styles.control_icons}>
          <div className={styles.edit_icon}>
            <button type="button" onClick={() => handleEdit(id)}>
              <MdOutlineModeEdit />
            </button>
          </div>
          <div className={styles.delete_icon}>
            <button type="button" onClick={() => handleDelete(id)}>
              <IoTrashOutline />
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => handleCloseModal("modal")}
        className={styles.roles_edit_modal}
      >
        <div className={styles.modal_content}>
          <h3 className={styles.edit_title_modal}>Edit Role</h3>
          <div className={styles.edit_wrapper_modal}>
            <div className={styles.role_name_modal}>
              <label>Role Name:</label>
              <input
                type="text"
                value={editedRoleName}
                onChange={(e) => setEditedRoleName(e.target.value)}
              />
            </div>
            <div className={styles.select_module}>
              <label>Select Module:</label>
              <div className={styles.wrapper}>
                <div className={styles.modules_list}>
                  {possible_modules
                    .slice()
                    .sort((a, b) => a.id - b.id)
                    .map((module) => (
                      <div
                        key={module.id}
                        onClick={() =>
                          handleItemClick(
                            module.id,
                            selectedModules,
                            setSelectedModules
                          )
                        }
                        className={clsx(styles.module, {
                          [styles.module_selected]: selectedModules.includes(
                            module.id
                          ),
                        })}
                      >
                        {module.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className={styles.select_employee}>
              <label>Select Employee:</label>
              <div className={styles.wrapper}>
                <div className={styles.employees_list}>
                  {possible_employees
                    .slice()
                    .sort((a, b) => a.id - b.id)
                    .map((employee) => (
                      <div
                        key={employee.id}
                        onClick={() =>
                          handleItemClick(
                            employee.id,
                            selectedEmployees,
                            setSelectedEmployees
                          )
                        }
                        className={clsx(styles.employee, {
                          [styles.employee_selected]:
                            selectedEmployees.includes(employee.id),
                        })}
                      >
                        {employee.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className={styles.save_button}
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isAssignEmployeesOpen}
        onClose={() => handleCloseModal("employees")}
        className={styles.roles_assign_employees_modal}
      >
        <div className={styles.modal_content}>
          <h3 className={styles.assign_title_modal}>Assign Employee</h3>
          <div className={styles.assign_wrapper_modal}>
            <div className={styles.select_employee}>
              <label>Select Employee:</label>
              <div className={styles.wrapper}>
                <div className={styles.employees_list}>
                  {possible_employees.map((employee) => (
                    <div
                      key={employee.id}
                      onClick={() =>
                        handleItemClick(
                          employee.id,
                          selectedEmployees,
                          setSelectedEmployees
                        )
                      }
                      className={clsx(styles.employee, {
                        [styles.employee_selected]: selectedEmployees.includes(
                          employee.id
                        ),
                      })}
                    >
                      {employee.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className={styles.save_button}
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isAssignModulesOpen}
        onClose={() => handleCloseModal("modules")}
        className={styles.roles_assign_modules_modal}
      >
        <div className={styles.modal_content}>
          <h3 className={styles.assign_title_modal}>Assign Modules</h3>
          <div className={styles.assign_wrapper_modal}>
            <div className={styles.select_module}>
              <label>Select Module:</label>
              <div className={styles.wrapper}>
                <div className={styles.modules_list}>
                  {possible_modules.map((module) => (
                    <div
                      key={module.id}
                      onClick={() =>
                        handleItemClick(
                          module.id,
                          selectedModules,
                          setSelectedModules
                        )
                      }
                      className={clsx(styles.module, {
                        [styles.module_selected]: selectedModules.includes(
                          module.id
                        ),
                      })}
                    >
                      {module.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className={styles.save_button}
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </Modal>

      <Modal
        message={modalMessage}
        isOpen={isRoleHasEmployeesOpen}
        onClose={() => handleCloseModal("delete")}
        className={styles.delete_message_modal}
      />
    </>
  );
};

TeamRoleRow.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  employees: PropTypes.array,
  module_permission: PropTypes.array,
  all_modules: PropTypes.array,
  all_employees: PropTypes.array,
};

export default TeamRoleRow;
