import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';

import Switch from '../../../../utilities/switch/switch';
import List from '../../../../utilities/list/list';

import styles from './printers-list.module.scss';

const PrintersList = ({ category, printers, onEdit, onDelete, onToggleActive, isLoading }) => {
  const renderSwitcher = (item) => <Switch id={item.id} isActive={item.is_active} onSwitch={onToggleActive} />;
  const renderTerminals = (terminals) => {
    return <span>{terminals.printer_terminals?.join(', ')}</span>;
  };
  const columnConfig = [
    {
      key: 'name',
      header: 'Printer Name',
    },
    {
      key: 'printer_type',
      header: 'Connection Type',
    },
    {
      key: 'printer_terminals',
      header: 'Terminal',
      render: renderTerminals,
    },
    {
      key: 'is_active',
      header: 'Active',
      render: renderSwitcher,
    },
  ];

  const rowActions = [
    {
      icon: <MdModeEditOutline />,
      onClick: (itemId) => onEdit(itemId, category),
      label: 'Edit employee',
      className: styles.row_actions_button,
    },
    { icon: <IoTrash />, onClick: onDelete, label: 'Delete employee', className: styles.row_actions_button },
  ];

  return (
    <List
      data={printers}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      isDragable={false}
    />
  );
};

export default PrintersList;
