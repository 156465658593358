import { useState, useMemo, useCallback, useEffect } from 'react';

export const usePrinterSettings = (orderTypes, initialPrinter = {}) => {
  const predefinedSettings = useMemo(() => {
    return orderTypes.flatMap((order) => [
      {
        order_type_id: order.id,
        order_types_name: order.name,
        print_type: 'Customer',
        manual_printing: false,
        auto_printing: false,
        status: false,
      },
      {
        order_type_id: order.id,
        order_types_name: order.name,
        print_type: 'Kitchen',
        manual_printing: false,
        auto_printing: false,
        status: false,
      },
    ]);
  }, [orderTypes]);

  const [printer, setPrinter] = useState({
    printer_settings_attribute: [],
    category_ids: [],
    ...initialPrinter,
  });

  const [printerSettings, setPrinterSettings] = useState(predefinedSettings);

  useEffect(() => {
    setPrinterSettings(predefinedSettings);
  }, [predefinedSettings]);

  useEffect(() => {
    setPrinter((prev) => ({
      ...prev,
      printer_settings_attribute: printerSettings
        .filter((setting) => setting.status)
        .map(({ status, order_types_name, ...rest }) => rest),
    }));
  }, [printerSettings]);

  const handleOrderTypes = useCallback((data, value) => {
    const { id, print_method, print_type, statusChanged } = JSON.parse(data);

    setPrinterSettings((prev) => {
      const newSettings = prev.slice();
      const index = newSettings.findIndex((s) => s.order_type_id === id && s.print_type === print_type);

      if (index !== -1) {
        newSettings[index] = {
          ...newSettings[index],
          [statusChanged ? 'status' : print_method]: value,
        };
      }

      return newSettings;
    });
  }, []);

  const handleInputChange = useCallback(
    (e, type) => {
      const { name, value } = e.target;

      if (name === 'receipt_print_type' && value === 'customer') {
        setPrinterSettings((prev) => [...prev.filter((s) => s.print_type !== 'Kitchen')]);
      }

      if (printer?.receipt_print_type === 'customer' && name === 'receipt_print_type' && value !== 'customer') {
        setPrinterSettings((prev) => {
          return [
            ...prev,
            ...predefinedSettings.filter((s) =>
              prev.find((p) => p.order_type_id !== s.order_type_id && p.print_type !== s.print_type),
            ),
          ];
        });
      }

      setPrinter((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [predefinedSettings, printer],
  );

  return {
    printer,
    printerSettings,
    handleOrderTypes,
    handleInputChange,
    setPrinter,
  };
};
