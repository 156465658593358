import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import {
  fetchEmployeesTimesheet,
  fetchAllEmployeesTimesheet,
} from "../../../../redux/timesheetSlice";

import { useAppContext } from "../../../../context/context";

import PageTitle from "../../../../components/utilities/page-title/page-title";
import Selector from "../../../../components//utilities/selector/selector";
import Pagination from "../../../../components/utilities/pagination/pagination";

import TimesheetList from "./timesheet-list";

import { IoIosArrowDown } from "react-icons/io";

import styles from "./timesheet.module.scss";

const PAGINATION_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const formatDate = (date) => {
  return date ? format(date, "yyyy-MM-dd") : "";
};

function Timesheet() {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const status = useSelector((state) => state.timesheet.status);
  const timesheet = useSelector((state) => state.timesheet.timesheet);
  const all_timesheet = useSelector((state) => state.timesheet.all_timesheet);

  const [allRecords, setAllRecords] = useState([]);

  const [selectedRole, setSelectedRole] = useState("All roles");
  const [selectedEmployee, setSelectedEmployee] = useState("All employees");
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const [records, setRecords] = useState([]);

  const [employeesOption, setEmployeesOption] = useState([]);
  const [rolesOption, setRolesOption] = useState([]);

  const defaultStartDate = new Date().toISOString().split("T")[0];
  const defaultEndDate = new Date().toISOString().split("T")[0];

  const [fromDate, setFromDate] = useState(defaultStartDate);
  const [toDate, setToDate] = useState(defaultEndDate);

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    dispatch(
      fetchEmployeesTimesheet({
        token,
        start_date: fromDate,
        end_date: toDate,
        team_role_id: selectedRoleId,
        employee_id: selectedEmployeeId,
      })
    );
    dispatch(
      fetchAllEmployeesTimesheet({
        token,
        start_date: fromDate,
        end_date: toDate,
      })
    );
  }, [dispatch, token, fromDate, toDate, selectedEmployeeId, selectedRoleId]);

  useEffect(() => {
    setAllRecords(all_timesheet.records);
  }, [all_timesheet]);

  useEffect(() => {
    if (timesheet.records?.length > 0 && !isInitialized) {
      setRecords(timesheet.records);

      setEmployeesOption([
        { label: "All employees", value: "all" },
        ...timesheet.records
          .map((item) => ({
            label: item.team_name,
            value: item.team_id,
            role: item.team_role,
          }))
          .filter(
            (employee, index, self) =>
              index === self.findIndex((r) => r.value === employee.value)
          ),
      ]);

      setRolesOption([
        { label: "All roles", value: "all" },
        ...timesheet.records
          .map((item) => ({
            label: item.team_role_name,
            value: item.team_role,
          }))
          .filter(
            (role, index, self) =>
              index === self.findIndex((r) => r.value === role.value)
          ),
      ]);

      setIsInitialized(true);
    }
  }, [timesheet, isInitialized]);

  const handleSelectRole = (value) => {
    if (value === "all") {
      setRecords(allRecords);
      setSelectedRoleId(null);
      setSelectedRole("All roles");
      /*       setEmployeesOption([
        { label: "All employees", value: "all" },
        ...timesheet.records
          .map((item) => ({
            label: item.team_name,
            value: item.team_id,
            role: item.team_role,
          }))
          .filter(
            (employee, index, self) =>
              index === self.findIndex((r) => r.value === employee.value)
          ),
      ]); */

      return;
    } else {
      setRecords(allRecords.filter((item) => item.team_role === +value));
      setSelectedRoleId(+value);
      setSelectedRole(value);
      /*       setEmployeesOption([
        { label: "All employees", value: "all" },
        ...timesheet.records
          .filter((item) => item.team_role === +value)
          .map((item) => ({
            label: item.team_name,
            value: item.team_id,
            role: item.team_role,
          }))
          .filter(
            (employee, index, self) =>
              index === self.findIndex((r) => r.value === employee.value)
          ),
      ]); */
    }
  };

  const handleSelectEmployee = (value) => {
    if (value === "all") {
      setRecords(allRecords);
      setSelectedEmployeeId(null);
      /*       setEmployeesOption([
        { label: "All employees", value: "all" },
        ...timesheet.records
          .map((item) => ({
            label: item.team_name,
            value: item.team_id,
            role: item.team_role,
          }))
          .filter(
            (employee, index, self) =>
              index === self.findIndex((r) => r.value === employee.value)
          ),
      ]); */
      setSelectedEmployee("All employees");
      return;
    } else {
      setRecords(allRecords.filter((item) => item.team_id === +value));
      setSelectedEmployeeId(+value);
      setSelectedEmployee(value);

      /*       setEmployeesOption([
        { label: "All employees", value: "all" },
        ...timesheet.records
          .map((item) => ({
            label: item.team_name,
            value: item.team_id,
            role: item.team_role,
          }))
          .filter(
            (employee, index, self) =>
              index === self.findIndex((r) => r.value === employee.value)
          ),
      ]); */
    }
  };

  const handleSelectFromDate = (date) => {
    const result = formatDate(date);

    setFromDate(result);
  };

  const handleSelectToDate = (date) => {
    const result = formatDate(date);

    setToDate(result);
  };

  return (
    <div className={styles.timesheet}>
      <PageTitle name={"Timesheet"} />
      <div className={styles.filters}>
        <div className={styles.filter_wrapper}>
          <label>Role</label>
          <Selector
            className={styles.selector}
            icon={IoIosArrowDown}
            items={rolesOption}
            selectedItem={selectedRole}
            onSelectItem={(newValue) => handleSelectRole(newValue)}
            initialPlaceholderVisibility={false}
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>Employee</label>
          <Selector
            className={styles.selector}
            icon={IoIosArrowDown}
            items={employeesOption}
            selectedItem={selectedEmployee}
            onSelectItem={(newValue) => handleSelectEmployee(newValue)}
            initialPlaceholderVisibility={false}
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>From Date</label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => handleSelectFromDate(date)}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>To Date</label>
          <DatePicker
            selected={toDate}
            onChange={(date) => handleSelectToDate(date)}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.container} role="table" aria-label="Data list">
          <TimesheetList records={records} isLoading={status === "loading"} fromDate={fromDate} toDate={toDate} />
        </div>
      </div>
      {/*       <Pagination
        pageSizeOptions={PAGINATION_OPTIONS}
        numberOfItems={18} //TODO: separately fetch employees to use this count...
        //currentPage={queryDetails.page}
        //currentPageSize={queryDetails.perPage}
        onPageSizeChange={handleSelectPageCount}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      /> */}
    </div>
  );
}

export default Timesheet;
