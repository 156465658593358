import { api, handleApiResponse } from "./base";

export const getCategories = (token) =>
  handleApiResponse(() =>
    api.get("categories", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addCategory = (newDetails, token) =>
  handleApiResponse(() =>
    api.post("categories", newDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const getCategory = (id, token) =>
  handleApiResponse(() =>
    api.put(`categories/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateCategory = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`categories/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const deleteCategory = (id, token) =>
  handleApiResponse(() =>
    api.delete(`categories/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateCategoryOrder = (id, old_position, new_position, token) =>
  handleApiResponse(() =>
    api.put(
      `categories/${id}/reorder?old_position=${old_position}&new_position=${new_position}`,
      {
        headers: {
          TOKEN: token,
        },
      }
    )
  );

export const updateCategoryStatus = (id, is_active, token) =>
  handleApiResponse(() =>
    api.put(`categories/${id}/active?is_active=${is_active}`, {}, {
      headers: {
        TOKEN: token,
      },
    })
  );
