import React from 'react';
import Chartkick from 'react-chartkick';
import { Chart } from 'chart.js'; // Named import for Chart.js
import { BarChart } from 'react-chartkick'; // Import BarChart from react-chartkick

// Register necessary components in Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController // Import the BarController
} from 'chart.js';

// Register the components globally
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController);

// Associate Chartkick with Chart.js
Chartkick.addAdapter(ChartJS);

const GrossSalesBarChart = ({ data }) => {
  const netSalesData = {
    "Today": data.today,
    "Yesterday": data.yesterday,
    "This Week": data.thisWeek,
    "This Month": data.thisMonth
  };

  return (
    <BarChart
      data={netSalesData}
      id="net-sale-chart"
      messages={{ empty: "No Records available." }}
      prefix="$"
      thousands=","
      round={2}
      colors={["#ED5950"]}
      max={600}
    />
  );
};

export default GrossSalesBarChart;