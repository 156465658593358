import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getEmployees, getEmployee, addEmployee, updateEmployee, deleteEmployee } from '../api/employees';

// Thunks
export const fetchEmployees = createAsyncThunk('employees/fetchEmployees', async ({ token, queryDetails }) => {
  const response = await getEmployees(token, queryDetails);
  return response.data;
});

export const fetchEmployeesForLength = createAsyncThunk('employees/fetchEmployeesForLength', async ({ token }) => {
  const response = await getEmployees(token);
  return response.data;
});

export const fetchEmployee = createAsyncThunk('employees/fetchEmployee', async ({ id, token }) => {
  const response = await getEmployee(id, token);
  return response.data;
});

export const addNewEmployee = createAsyncThunk('employees/addEmployee', async ({ newEmployeeDetails, token }) => {
  const response = await addEmployee(newEmployeeDetails, token);
  return response.data;
});

export const updateExistingEmployee = createAsyncThunk(
  'employees/updateExistingEmployee',
  async ({ id, updatedDetails, token }) => {
    await updateEmployee(id, updatedDetails, token);
    return { id, updatedDetails };
  },
);

export const removeEmployee = createAsyncThunk('employees/removeEmployee', async ({ id, token }) => {
  await deleteEmployee(id, token);
  return id;
});

const initialState = {
  employees: [],
  currentEmployee: {},
  numberOfAllEmployees: 0,
  queryDetails: {},
  status: 'idle',
  error: null,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    };

    builder
      .addCase(fetchEmployees.pending, setLoading)
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        setSucceeded(state);
        state.employees = action.payload;
      })
      .addCase(fetchEmployees.rejected, setFailed)

      .addCase(fetchEmployeesForLength.fulfilled, (state, action) => {
        state.numberOfAllEmployees = action.payload.length;
      })

      .addCase(fetchEmployee.pending, setLoading)
      .addCase(fetchEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentEmployee = action.payload;
      })
      .addCase(fetchEmployee.rejected, setFailed)

      .addCase(addNewEmployee.pending, setLoading)
      .addCase(addNewEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        state.employees.push(action.payload);
      })
      .addCase(addNewEmployee.rejected, setFailed)

      .addCase(updateExistingEmployee.pending, setLoading)
      .addCase(updateExistingEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.employees.findIndex((emp) => emp.id === updatedDetails.id);

        if (index !== -1) {
          state.employees[index] = updatedDetails;
        }
      })
      .addCase(updateExistingEmployee.rejected, setFailed)

      .addCase(removeEmployee.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.employees = state.employees.filter((employee) => employee.id !== id);
      })
      .addCase(removeEmployee.pending, setLoading)
      .addCase(removeEmployee.rejected, setFailed);
  },
});

export const { setQueryDetails } = employeesSlice.actions;
export default employeesSlice.reducer;
