import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import { fetchEmployeeTimesheetDetails } from "../../../../redux/timesheetSlice";

import { useAppContext } from "../../../../context/context";

import PageTitle from "../../../../components/utilities/page-title/page-title";

import TimesheetDetailsList from "./timesheet-details-list";

import styles from "./timesheet.module.scss";

const formatDate = (date) => {
  return date ? format(date, "yyyy-MM-dd") : "";
};

function TimesheetDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();
  const { token } = useAppContext();

  const status = useSelector((state) => state.timesheet.status);
  const timesheet_details_records = useSelector(
    (state) => state.timesheet.timesheet_details_records
  );

  const [employeeName, setEmployeeName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [TimesheetRecords, setTimesheetRecords] = useState([]);

  useEffect(() => {
    if (!fromDate || !toDate) return;

    dispatch(
      fetchEmployeeTimesheetDetails({
        token,
        start_date: fromDate,
        end_date: toDate,
        employee_id: id,
      })
    );
  }, [dispatch, token, fromDate, toDate]);

  useEffect(() => {
    setTimesheetRecords(timesheet_details_records);
  }, [timesheet_details_records]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFromDate(params.get("from_date"));
    setToDate(params.get("to_date"));
    setEmployeeName(params.get("employee_name"));
  }, [location]);

  const handleSelectFromDate = (date) => {
    const result = formatDate(date);

    setFromDate(result);
  };

  const handleSelectToDate = (date) => {
    const result = formatDate(date);

    setToDate(result);
  };

  const handleEditTimesheetDetailsClick = (recordId) => {
    const path = `/employee/${id}/edit_timesheet_details?from_date=${fromDate}&to_date=${toDate}&employee_name=${employeeName}&record_id=${recordId}`;

    navigate(path);
  };

  console.log("timesheet_details_records", timesheet_details_records);
  console.log("TimesheetRecords", TimesheetRecords);

  return (
    <div className={styles.timesheet}>
      <PageTitle name={`${employeeName}s Timecard`} />
      <div className={styles.filters}>
        <div className={styles.filter_wrapper}>
          <label>From Date</label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => handleSelectFromDate(date)}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>

        <div className={styles.filter_wrapper}>
          <label>To Date</label>
          <DatePicker
            selected={toDate}
            onChange={(date) => handleSelectToDate(date)}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Select a date"
          />
        </div>
      </div>
      <div className={styles.table}>
        <div className={styles.container} role="table" aria-label="Data list">
          <TimesheetDetailsList
            employeeRecords={TimesheetRecords}
            onEdit={handleEditTimesheetDetailsClick}
            isLoading={status === "loading"}
          />
        </div>
      </div>
    </div>
  );
}

export default TimesheetDetails;
