import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTerminalSettings,
  getTerminalSetting,
  addTerminalSetting,
  updateTerminalSetting,
  deleteTerminalSetting,
} from '../api/terminal_settings';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchTerminalSettings = createAsyncThunk('terminalSettings/fetchTerminalSettings', async ({ token }) => {
  const response = await getTerminalSettings(token);
  return response.data;
});

export const fetchTerminalSetting = createAsyncThunk('terminalSettings/fetchTerminalSetting', async ({ id, token }) => {
  const response = await getTerminalSetting(id, token);
  return response.data;
});

export const addNewTerminalSetting = createAsyncThunk(
  'terminalSettings/addNewTerminalSetting',
  async ({ newTerminalSetting, token }, { dispatch }) => {
    try {
      const response = await addTerminalSetting(newTerminalSetting, token);
      dispatch(
        addNotification({
          message: 'Terminal setting successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create terminal setting, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingTernialSetting = createAsyncThunk(
  'terminalSettings/updateExistingTernialSetting',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateTerminalSetting(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Terminal setting successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update terminal setting, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeTerminalSetting = createAsyncThunk(
  'terminalSettings/removeTerminalSetting',
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteTerminalSetting(id, token);
      dispatch(
        addNotification({
          message: 'Terminal setting successfully deleted',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete terminal setting, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  terminalSettings: [],
  currentterminalSetting: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const terminalSettingsSlice = createSlice({
  name: 'terminalSettings',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchTerminalSettings.pending, setLoading)
      .addCase(fetchTerminalSettings.fulfilled, (state, action) => {
        setSucceeded(state);
        console.log(action.payload);
        state.terminalSettings = action.payload;
      })
      .addCase(fetchTerminalSettings.rejected, setFailed)

      .addCase(fetchTerminalSetting.pending, setLoading)
      .addCase(fetchTerminalSetting.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentterminalSetting = action.payload;
      })
      .addCase(fetchTerminalSetting.rejected, setFailed)

      .addCase(addNewTerminalSetting.pending, setLoading)
      .addCase(addNewTerminalSetting.fulfilled, (state, action) => {
        setSucceeded(state);
        state.terminalSettings.push(action.payload);
      })
      .addCase(addNewTerminalSetting.rejected, setFailed)

      .addCase(updateExistingTernialSetting.pending, setLoading)
      .addCase(updateExistingTernialSetting.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.terminalSettings.findIndex((setting) => setting.id === updatedDetails.id);

        if (index !== -1) {
          state.terminalSettings[index] = updatedDetails;
        }
      })
      .addCase(updateExistingTernialSetting.rejected, setFailed)

      .addCase(removeTerminalSetting.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.terminalSettings = state.terminalSettings.filter((setting) => setting.id !== id);
      })
      .addCase(removeTerminalSetting.pending, setLoading)
      .addCase(removeTerminalSetting.rejected, setFailed);
  },
});

export const { setQueryDetails } = terminalSettingsSlice.actions;
export default terminalSettingsSlice.reducer;
