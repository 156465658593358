import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/context";

import {
  fetchNotes,
  addNote,
  updateExistingNote,
  removeNote,
  updateExistingNoteStatus,
  updateExistingNoteOrder,
} from "../../../../redux/dynamicNotesSlice";
import { addNotification } from "../../../../redux/tooltipSlice";

import PageTitle from "../../../utilities/page-title/page-title";
import Input from "../../../utilities/input/input";
import Button from "../../../utilities/button/button";

import DynamicNotesList from "./order-notes-list";

import clsx from "clsx";
import styles from "./order-notes.module.scss";

function OrderNotes() {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const notes = useSelector((state) => state.notes.notes);
  const notesStatus = useSelector((state) => state.notes.status);

  // Add new tax
  const [newNote, setNewNote] = useState("");

  // Edit note
  const [editedNotes, setEditedNotes] = useState({});

  useEffect(() => {
    if (token) {
      dispatch(fetchNotes(token));
    }
  }, [dispatch, token]);

  const handleAdd = useCallback(() => {
    const newDetails = {
      name: newNote,
      location_id: locationId,
      is_active: true,
    };
    dispatch(addNote({ newDetails, token }));
    setNewNote("");
  }, [dispatch, locationId, newNote, token]);

  const handleUpdate = async () => {
    try {
      await Promise.all(
        Object.entries(editedNotes).map(([id, updatedDetails]) =>
          dispatch(updateExistingNote({ id, updatedDetails, token }))
        )
      );
      setEditedNotes({});
    } catch (error) {
      dispatch(
        addNotification({
          message: "Notes wasn`t updated, something went wrong: " + error,
          status: "failed",
        })
      );
    }
  };

  const handleInputChange = useCallback(
    (id, field, value) => {
      const existingNote = notes.find((note) => note.id === id);

      if (existingNote) {
        setEditedNotes((prev) => ({
          ...prev,
          [id]: {
            ...existingNote,
            ...prev[id],
            [field]: value,
          },
        }));
      }
    },
    [notes]
  );

  const handleChangeList = useCallback(
    (sortedNotes) => {
      console.log("sortedNotes", sortedNotes);
      /* try {
        sortedNotes.forEach((updatedNote) => {
          const originalNote = notes.find((note) => note.id === updatedNote.id);

          if (originalNote && originalNote.sort !== updatedNote.sort) {
            dispatch(
              updateExistingNoteOrder({
                id: updatedNote.id,
                old_position: originalNote.sort,
                new_position: updatedNote.sort,
              })
            );
          }
        });
      } catch (error) {
        dispatch(
          addNotification({
            message: "Notes order wasn`t updated, something went wrong",
            status: "failed",
          })
        );
      } */
    },
    [dispatch, notes]
  );

  const handleDelete = useCallback(
    (id) => {
      dispatch(removeNote({ id, token }));
    },
    [dispatch, token]
  );

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingNoteStatus({ id, is_active: value, token }));
    },
    [dispatch, token]
  );

  return (
    <div className={styles.dynamic_notes}>
      <PageTitle name={"Dynamic Notes"} />
      <div className={styles.add_note}>
        <div className={styles.section}>
          <Input
            label={"Note Name"}
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            placeholder={"Note Name"}
            required={true}
            className={styles.new_name_input}
          />
        </div>
        <Button
          className={styles.add_button}
          title={"Add Order Note"}
          onClick={handleAdd}
        />
      </div>
      <DynamicNotesList
        items={notes}
        onEdit={handleInputChange}
        onListChange={handleChangeList}
        editedNotes={editedNotes}
        setEditedNotes={setEditedNotes}
        onDelete={handleDelete}
        onToggleActive={handleToggleActive}
        isLoading={notesStatus === "loading"}
      />
      <Button
        className={styles.update_button}
        title={"Update"}
        onClick={handleUpdate}
      />
    </div>
  );
}

export default OrderNotes;
