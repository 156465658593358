import dashboardStore from './dashboard-store';

export const api = {
  get: (url, config) => {
    return new Promise((resolve, reject) => {
      const path = url.split('/');
      const id = path[1];

      if (!!url && !!config.headers.TOKEN) {
        setTimeout(
          () =>
            id
              ? resolve({ data: { data: dashboardStore.getMetrics() } })
              : resolve({ data: { data: dashboardStore.getMetric(id) } }),
          1000,
        );
      } else {
        reject(new Error('Token or url is not provided'));
      }
    });
  }
};
