import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getTeamRoles,
  addTeamRole,
  updateTeamRole,
  deleteTeamRole,
  getModules,
  getEmployees,
} from "../api/team_roles";

import { addNotification } from "./tooltipSlice";

// Thunks
export const fetchTeamRoles = createAsyncThunk(
  "roles/fetchRoles",
  async (token) => {
    const response = await getTeamRoles(token);
    return response.data.team_roles;
  }
);

export const createTeamRole = createAsyncThunk(
  "roles/addRole",
  async ({ newDetails, token }, { dispatch }) => {
    try {
      const response = await addTeamRole(newDetails, token);
      dispatch(
        addNotification({
          message: "Role created successfully",
          status: "succeeded",
        })
      );
      return response.data.team_roles;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to create role", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingTeamRole = createAsyncThunk(
  "roles/updateExistingRole",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      const response = await updateTeamRole(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Role updated successfully",
          status: "succeeded",
        })
      );
      return response.data.team_roles;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update role", status: "failed" })
      );
      throw error;
    }
  }
);

export const removeTeamRole = createAsyncThunk(
  "roles/removeRole",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteTeamRole(id, token);
      dispatch(
        addNotification({
          message: "Role deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to delete role", status: "failed" })
      );
      throw error;
    }
  }
);

export const fetchModules = createAsyncThunk(
  "roles/fetchModules",
  async (token) => {
    const response = await getModules(token);
    return response.data;
  }
);

export const fetchEmployees = createAsyncThunk(
  "roles/fetchEmployees",
  async (token) => {
    const response = await getEmployees(token);
    return response.data;
  }
);

const initialState = {
  roles: [],
  modules: [],
  employees: [],
  nameOptions: [],
  status: "idle",
  error: null,
};

const teamRolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchTeamRoles.pending, setLoading)
      .addCase(fetchTeamRoles.fulfilled, (state, action) => {
        setSucceeded(state);
        state.roles = action.payload;
        state.nameOptions = state.roles.map((item) => ({
          label: item.name,
          value: item.id,
        }));
      })
      .addCase(fetchTeamRoles.rejected, setFailed)

      .addCase(createTeamRole.pending, setLoading)
      .addCase(createTeamRole.fulfilled, (state, action) => {
        setSucceeded(state);
        state.roles = action.payload;
      })
      .addCase(createTeamRole.rejected, setFailed)

      .addCase(updateExistingTeamRole.pending, setLoading)
      .addCase(updateExistingTeamRole.fulfilled, (state, action) => {
        setSucceeded(state);
        state.roles = action.payload;
      })
      .addCase(updateExistingTeamRole.rejected, setFailed)

      .addCase(removeTeamRole.fulfilled, (state, action) => {
        state.roles = state.roles.filter((role) => role.id !== action.payload);
      })

      .addCase(fetchModules.pending, setLoading)
      .addCase(fetchModules.fulfilled, (state, action) => {
        setSucceeded(state);
        state.modules = action.payload;
      })
      .addCase(fetchModules.rejected, setFailed)

      .addCase(fetchEmployees.pending, setLoading)
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        setSucceeded(state);
        state.employees = action.payload;
      })
      .addCase(fetchEmployees.rejected, setFailed);
  },
});

export default teamRolesSlice.reducer;
