import React from "react";

import List from "../../../utilities/list/list";
import Switch from "../../../utilities/switch/switch";

import { IoTrash } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import styles from "./tips-list.module.scss";
import { render } from "@testing-library/react";

const TipsList = ({
  items,
  onEdit,
  onDelete,
  editedTips,
  setEditedTips,
  onToggleActive,
  isLoading,
}) => {
  const renderDragAndDropIcon = (item) => (
    <div className={styles.drag_icon}>
      <GiHamburgerMenu />
    </div>
  );

  const renderInputName = (item) => (
    <input
      type="text"
      id={item.id}
      value={
        editedTips[item.id]?.name !== undefined
          ? editedTips[item.id].name
          : item.name
      }
      placeholder="Add Email Address"
      onChange={(e) => onEdit(item.id, "name", e.target.value)}
      className={styles.tip_input}
    />
  );

  const renderInputRate = (item) => (
    <input
      type="number"
      id={item.id}
      value={
        editedTips[item.id]?.rate !== undefined
          ? editedTips[item.id].rate
          : item.rate
      }
      placeholder="Add Email Address"
      onChange={(e) => onEdit(item.id, "rate", Number(e.target.value))}
      className={styles.rate_input}
    />
  );

  const renderSwitcher = (item) => (
    <Switch
      id={item.id}
      roleName={"User"}
      isActive={editedTips[item.id]?.is_active || false}
      onSwitch={onToggleActive}
    />
  );

  const columnConfig = [
    {
      key: "sort",
      header: "Sort",
      render: renderDragAndDropIcon,
    },
    {
      key: "name",
      header: "Tip Name",
      render: renderInputName,
    },
    {
      key: "rate",
      header: "Tip %",
      render: renderInputRate,
    },
    {
      key: "is_active",
      header: "Status",
      render: renderSwitcher,
    },
  ];

  const rowActions = [
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: "Delete category",
      className: styles.row_actions_button,
    },
  ];

  return (
    <List
      //isDragable={true}
      data={items}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
    />
  );
};

export default TipsList;
