import metricsData from './metrics-data.json';

const metricsStore = (() => {
  let metrics = metricsData.data;

  const getMetrics = () => {
    return metrics;
  };

  const getMetric = (id) => {
    return metrics.find((metric) => metric.id === id);
  };

  return {
    getMetrics,
    getMetric
  };
})();

export default metricsStore;
