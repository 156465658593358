import { z } from 'zod';

const addressRegistrationSchema = z.object({
  address1: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(60, { message: 'Please enter 60 characters or fewer.' })
    .optional(),
  address2: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(60, { message: 'Please enter 60 characters or fewer.' })
    .optional(),
  state: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(30, { message: 'Please enter 30 characters or fewer.' })
    .optional(),
  city: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(30, { message: 'Please enter 30 characters or fewer.' })
    .optional(),
  postcode: z
    .string()
    .regex(/^[-a-zA-Z0-9,]+(\s[-a-zA-Z0-9,]+)*$/, 'Please enter valid value.')
    .max(10, { message: 'Please enter 10 characters or fewer.' })
    .optional(),
});

export const registrationSchema = z.object({
  first_name: z
    .string({ required_error: 'Please enter First Name' })
    .min(1)
    .max(20, { message: 'First Name can contain a maximum of 20 characters' }),
  last_name: z.string().max(20, 'Last Name can contain maximum upto 20 characters').optional(),
  email: z.string({ required_error: 'Email is required' }).min(1).email('Please enter valid Email Address'),
  addresses: z.array(addressRegistrationSchema),
});
