import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchLocations,
  updateGiftCardPosition,
} from "../../../../redux/locationSlice";
import { useAppContext } from "../../../../context/context";

import PageTitle from "../../../utilities/page-title/page-title";
import Checkbox from "../../../utilities/checkbox/checkbox";
import Button from "../../../utilities/button/button";

import styles from "./settings.module.scss";

function Settings() {
  // Global State
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();
  const locations = useSelector((state) => state.locations.locations);

  // Local State
  const [currentLocation, setCurrentLocation] = useState({});
  const [isFirstPosition, setIsFirstPosition] = useState(true);
  const [flag, setFlag] = useState(false);

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (locations) {
      const currentLocation = locations.find(
        (location) => location.id === locationId
      );
      if (currentLocation) setCurrentLocation(currentLocation);
    }
  }, [locations, locationId]);

  useEffect(() => {
    if (currentLocation) {
      setIsFirstPosition(currentLocation.show_gift_card_at_first_position);
    }
  }, [currentLocation]);

  // Handlers
  const handleCheckboxChange = (position) => {
    if (isFirstPosition !== position) {
      setIsFirstPosition(position);
      setFlag(true);
    }
  };

  const handleSave = () => {
    if (flag) {
      dispatch(
        updateGiftCardPosition({
          isGiftCardAtFirstPosition: isFirstPosition,
          token,
          locationId,
        })
      );
      setFlag(false);
    }
  };

  console.log("locations", locations);
  console.log("isFirstPosition", isFirstPosition);
  console.log("currentLocation", currentLocation);

  return (
    <div className={styles.menu_settings}>
      <PageTitle name={"Menu Settings"} />
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: "Show Gift Card at first position" }}
          handleOnChange={() => handleCheckboxChange(true)}
          isChecked={isFirstPosition === true}
        />
        <span
          className={styles.menu_item_name}
          onClick={() => handleCheckboxChange(true)}
        >
          Show Gift Card at first position
        </span>
      </div>
      <div className={styles.menu_item}>
        <Checkbox
          item={{ name: "Show Gift Card at last position" }}
          handleOnChange={() => handleCheckboxChange(false)}
          isChecked={isFirstPosition === false}
        />
        <span
          className={styles.menu_item_name}
          onClick={() => handleCheckboxChange(false)}
        >
          Show Gift Card at last position
        </span>
      </div>
      <Button
        className={styles.save_button}
        title={"Save"}
        onClick={handleSave}
      />
    </div>
  );
}

export default Settings;
