import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotes,
  addNewNote,
  updateNote,
  deleteNote,
  updateNoteStatus,
  updateNoteOrder,
} from "../api/dynamic_notes";

import { addNotification } from "./tooltipSlice";

export const fetchNotes = createAsyncThunk("notes/fetchNotes", async () => {
  const response = await getNotes();
  return response.data;
});

export const addNote = createAsyncThunk(
  "notes/addNote",
  async ({ newDetails, token }, { dispatch }) => {
    try {
      const response = await addNewNote(newDetails, token);
      dispatch(
        addNotification({
          message: "Note created successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to create note", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingNote = createAsyncThunk(
  "notes/updateExistingNote",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateNote(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Note was updated successfully",
          status: "succeeded",
        })
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update note", status: "failed" })
      );
      throw error;
    }
  }
);

export const removeNote = createAsyncThunk(
  "notes/removeNote",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteNote(id, token);
      dispatch(
        addNotification({
          message: "Note deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to delete note", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingNoteStatus = createAsyncThunk(
  "notes/updateExistingNoteStatus",
  async ({ id, is_active, token }, { dispatch }) => {
    try {
      await updateNoteStatus(id, is_active, token);
      dispatch(
        addNotification({
          message: "Note status was updated successfully",
          status: "succeeded",
        })
      );
      return { id, is_active };
    } catch (error) {
      dispatch(
        addNotification({
          message: "Failed to update note status",
          status: "failed",
        })
      );
      throw error;
    }
  }
);

export const updateExistingNoteOrder = createAsyncThunk(
  "notes/updateExistingNoteOrder",
  async ({ id, old_position, new_position, token }) => {
    try {
      await updateNoteOrder(id, old_position, new_position, token);
      return { id, new_position };
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  notes: [],
  status: "idle",
  error: null,
};

const dynamicNotesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchNotes.pending, setLoading)
      .addCase(fetchNotes.fulfilled, (state, action) => {
        setSucceeded(state);
        state.notes = action.payload;
      })
      .addCase(fetchNotes.rejected, setFailed)

      .addCase(addNote.pending, setLoading)
      .addCase(addNote.fulfilled, (state, action) => {
        setSucceeded(state);
        state.notes.push(action.payload);
      })
      .addCase(addNote.rejected, setFailed)

      .addCase(updateExistingNote.pending, setLoading)
      .addCase(updateExistingNote.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingNote = state.notes.find((note) => note.id === Number(id));
        if (existingNote) {
          Object.assign(existingNote, updatedDetails);
        }
      })
      .addCase(updateExistingNote.rejected, setFailed)

      
      .addCase(removeNote.fulfilled, (state, action) => {
        state.notes = state.notes.filter((note) => note.id !== action.payload);
      })

      .addCase(updateExistingNoteStatus.pending, setLoading)
      .addCase(updateExistingNoteStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, is_active } = action.payload;
        const existingNote = state.notes.find((note) => note.id === id);

        if (existingNote) {
          existingNote.is_active = is_active;
        }
      })
      .addCase(updateExistingNoteStatus.rejected, setFailed)
      
      //.addCase(updateExistingNoteOrder.pending, setLoading)
      .addCase(updateExistingNoteOrder.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, new_position } = action.payload;
        const existingNote = state.notes.find((note) => note.id === id);

        if (existingNote) {
          existingNote.sort = new_position;
        }
      })
      .addCase(updateExistingNoteOrder.rejected, setFailed);
  },
});

export default dynamicNotesSlice.reducer;
