import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getTipsList,
  deleteTip,
  updateTip,
  addNewTip,
  updateTipStatus,
} from "../api/tips";

import { addNotification } from "./tooltipSlice";

export const fetchTips = createAsyncThunk("tips/fetchTips", async (token) => {
  const response = await getTipsList(token);
  return response.data;
});

export const addTip = createAsyncThunk(
  "tips/addTip",
  async ({newDetails, token}, { dispatch }) => {
    try {
      const response = await addNewTip(newDetails, token);
      dispatch(
        addNotification({
          message: "Tip created successfully",
          status: "succeeded",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to create tip", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingTip = createAsyncThunk(
  "tips/updateTip",
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateTip(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: "Tip updated successfully",
          status: "succeeded",
        })
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update tip", status: "failed" })
      );
      throw error;
    }
  }
);

export const removeTip = createAsyncThunk(
  "tips/removeTip",
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteTip(id, token);
      dispatch(
        addNotification({
          message: "Tip deleted successfully",
          status: "succeeded",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to delete tip", status: "failed" })
      );
      throw error;
    }
  }
);

export const updateExistingTipStatus = createAsyncThunk(
  "tips/updateTipStatus",
  async ({ id, is_active, token }, { dispatch }) => {
    try {
      await updateTipStatus(id, is_active, token);
      dispatch(
        addNotification({
          message: "Tip status updated successfully",
          status: "succeeded",
        })
      );
      return { id, is_active };
    } catch (error) {
      dispatch(
        addNotification({ message: "Failed to update tip status", status: "failed" })
      );
      throw error;
    }
  }
);

const initialState = {
  tips: [],
  status: "idle",
  error: null,
};

const tipsSlice = createSlice({
  name: "tips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = "loading";
    };

    const setSucceeded = (state) => {
      state.status = "succeeded";
    };

    const setFailed = (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    };

    builder
      .addCase(fetchTips.pending, setLoading)
      .addCase(fetchTips.fulfilled, (state, action) => {
        setSucceeded(state);
        state.tips = action.payload;
      })
      .addCase(fetchTips.rejected, setFailed)

      .addCase(removeTip.fulfilled, (state, action) => {
        state.tips = state.tips.filter((tip) => tip.id !== action.payload);
      })

      .addCase(updateExistingTip.pending, setLoading)
      .addCase(updateExistingTip.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, updatedDetails } = action.payload;
        const existingTip = state.tips.find((tip) => tip.id === Number(id));

        if (existingTip) {
          Object.assign(existingTip, updatedDetails);
        }
      })
      .addCase(updateExistingTip.rejected, setFailed)

      .addCase(addTip.pending, setLoading)
      .addCase(addTip.fulfilled, (state, action) => {
        setSucceeded(state);
        state.tips.push(action.payload);
      })
      .addCase(addTip.rejected, setFailed)
      
      .addCase(updateExistingTipStatus.pending, setLoading)
      .addCase(updateExistingTipStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { id, is_active } = action.payload;
        const existingTip = state.tips.find((tip) => tip.id === id);
        if (existingTip) {
          existingTip.is_active = is_active;
        }
      })
      .addCase(updateExistingTipStatus.rejected, setFailed);
  },
});

export default tipsSlice.reducer;
