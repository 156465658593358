import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getMetricsData,
} from "../api/dashboard";

// Thunks
export const fetchMetricsData = createAsyncThunk(
  "dashboard/fetchMetricsData",
  async (token) => {
    const response = await getMetricsData(token);
    return response.data;
  }
);

const initialState = {
    dashboard_data: [],
    status: "idle",
    error: null,
  };

  const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      const setLoading = (state) => {
        state.status = "loading";
      };
  
      const setSucceeded = (state) => {
        state.status = "succeeded";
      };
  
      const setFailed = (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      };
  
      builder
        .addCase(fetchMetricsData.pending, setLoading)
        .addCase(fetchMetricsData.fulfilled, (state, action) => {
          setSucceeded(state);
          state.dashboard_data = action.payload;
        })
        .addCase(fetchMetricsData.rejected, setFailed);
    },
  });
  
  export default dashboardSlice.reducer;