import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { addModifier } from '../../../../redux/modifierSetsSlice';

import ModifierForm from './forms/modifier-form';

import PageTitle from '../../../utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Button from '../../../utilities/button/button';
import DynamicFormFields from '../../../utilities/dynamic-form-fields/dynamic-form-fields ';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import clsx from 'clsx';
import styles from './modifiers.module.scss';

const AddModifierSet = () => {
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const modifierSetsStatus = useSelector((state) => state.modifierSets.status);
  const [modifier, setModifier] = useState({});

  const statusLoading = modifierSetsStatus === 'loading';

  const handleInputChange = useCallback(
    (e, type) => {
      const { name, value } = e.target;
      setModifier((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setModifier],
  );

  const memoizedOnChange = useCallback(
    (value) => handleInputChange({ target: { value, name: 'modifiers' } }),
    [handleInputChange],
  );

  const handleCancel = () => {
    navigate('/menu/modifiers');
  };

  const handleSaveChanges = async () => {
    try {
      await dispatch(addModifier({ newModifierDetails: modifier, token })).unwrap();
      navigate('/menu/modifiers');
    } catch {}
  };

  return (
    <section className={styles.option_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Create Modifier'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.row}>
              <div className={styles.field}>
                <Input
                  label={'Modifier Set Name'}
                  value={modifier.name}
                  onChange={handleInputChange}
                  name={'name'}
                  required={true}
                />
              </div>
              <div className={clsx(styles.field, styles.block)}>
                <DynamicFormFields
                  value={modifier.modifiers}
                  label={'Modifiers'}
                  fieldItem={ModifierForm}
                  fieldType={'text'}
                  fieldProps={{ style: { margin: '0 auto' } }}
                  addButtonTitle={'Add Modifier'}
                  onChange={memoizedOnChange}
                  isDragable={true}
                  bottomLine={true}
                  rowClassName={'table_row'}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button className={styles.button} disabled={statusLoading} title={'Save'} onClick={handleSaveChanges} />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddModifierSet;
