import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  fetchModifiers,
  addModifier,
  removeModifier,
  reorderModifier,
  setQueryDetails,
} from '../../../../redux/modifierSetsSlice';

import { useAppContext } from '../../../../context/context';
import usePagination from '../../business/order-types/usePagination';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Pagination from '../../../../components/utilities/pagination/pagination';
import Button from '../../../../components/utilities/button/button';
import Search from '../../../../components/utilities/search/search';

import ModifierSetsList from './modifier-sets-list';

import styles from './modifiers.module.scss';

const PAGINATION_OPTIONS = [
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 10, value: 10 },
  { label: 15, value: 15 },
  { label: 20, value: 20 },
  { label: 25, value: 25 },
];

const PAGINATION = {
  page: 1,
  perPage: 5,
};

const getModifierSet = (modifiers, id) => {
  return modifiers.find((modifier) => modifier.id === parseFloat(id));
};

function Modifiers() {
  const navigate = useNavigate();

  const modifierSetsStatus = useSelector((state) => state.modifierSets.status);
  const modifierSets = useSelector((state) => state.modifierSets.modifierSets);

  const dispatch = useDispatch();
  const { token } = useAppContext();

  const {
    currentPageItems,
    page,
    perPage,
    isPrevActive,
    isNextActive,
    handleSelectPageCount,
    handleClickPrev,
    handleClickNext,
  } = usePagination(modifierSets, PAGINATION.page, PAGINATION.perPage);

  useEffect(() => {
    if (token) {
      dispatch(fetchModifiers({ token }));
    }
  }, [dispatch, token]);

  const handleSetResult = (result) => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleSearchClean = () => {
    dispatch(setQueryDetails({ search: '' }));
  };

  const handleAddNewModifierSet = () => {
    navigate(`/menu/modifiers/new`);
  };

  const handleEditModifierSet = (id) => {
    navigate(`/menu/modifiers/${id}/edit`);
  };

  const handleDeleteModifierSet = (id) => {
    dispatch(removeModifier({ id, token }));
  };

  const handleCloneModifierSet = (id) => {
    dispatch(addModifier({ newModifierDetails: getModifierSet(modifierSets, id), token }));
  };

  const handleDragModifier = (item) => {
    const old_position = currentPageItems.find((modifier) => modifier.id === item.id)?.sort;
    const new_position = currentPageItems[item.index]?.sort;

    dispatch(reorderModifier({ id: item.id, updatedDetails: { id: item.id, old_position, new_position }, token }));
  };

  return (
    <>
      <PageTitle name={'Modifiers'} />
      <div className={styles.list_controller}>
        <div className={styles.filter_container}>
          <div className={styles.search}>
            <Search
              placeholder="Search Modifier by Set Name"
              onSearchResults={handleSetResult}
              onClear={handleSearchClean}
              autoFilter={false}
            />
          </div>
        </div>
        <div>
          <Button title={'Add New Modifier'} onClick={handleAddNewModifierSet} />
        </div>
      </div>
      <ModifierSetsList
        modifiers={currentPageItems}
        onClone={handleCloneModifierSet}
        onEdit={handleEditModifierSet}
        onDelete={handleDeleteModifierSet}
        onDrag={handleDragModifier}
        isLoading={modifierSetsStatus === 'loading'}
        isFailed={modifierSetsStatus === 'failed'}
      />
      <Pagination
        pageSizeOptions={PAGINATION_OPTIONS}
        numberOfItems={modifierSets.length}
        currentPage={page}
        currentPageSize={perPage}
        onPageSizeChange={handleSelectPageCount}
        inputPaginationControl={{
          isActive: true,
          isPrevActive,
          isNextActive,
        }}
        onPrev={handleClickPrev}
        onNext={handleClickNext}
      />
    </>
  );
}

export default Modifiers;
