import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useAppContext } from "../../../../context/context";

import { fetchServiceCharges } from "../../../../redux/serviceChargesSlice";

import Spinner from "../../../utilities/spinner/spinner";
import ServiceChargeForm from "./service-charge-form";

import styles from "./service-charges.module.scss";

function ServiceChanges() {
  const dispatch = useDispatch();

  const { token, locationId } = useAppContext();

  const service_charges = useSelector((state) => state.charges.service_charges);
  const serviceChargesStatus = useSelector((state) => state.charges.status);

  const [formForNewCharge, setFormForNewCharge] = useState([]);

  useEffect(() => {
    if (serviceChargesStatus === "idle") {
      dispatch(fetchServiceCharges(token));
    }
  }, [dispatch, serviceChargesStatus, token]);

  const handleAdd = () => {
    setFormForNewCharge([
      {
        id: 999,
        name: "",
        location_id: locationId,
        percentage: 10.10,
        is_enabled: false,
        min_guest_count: 1,
        max_guest_count: 100,
        order_type: "DineIn",
      },
    ]);
  };

  const sortedServiceCharges = [...service_charges].sort((a, b) => a.id - b.id);

  if (!sortedServiceCharges || sortedServiceCharges.length === 0)
    return <Spinner />;

  return (
    <div className={styles.charges}>
      <h2 className={styles.title}>Service Charges</h2>

      {sortedServiceCharges.map((charge) => {
        if (charge.order_type !== "DineIn") {
          return (
            <ServiceChargeForm
              key={charge.id}
              id={charge.id}
              typeName={charge.order_type}
              initialName={charge.name}
              initialRate={charge.percentage}
              initialStatus={charge.is_enabled}
              initialMinGuestCount={charge.min_guest_count}
              initialMaxGuestCount={charge.max_guest_count}
            />
          );
        }
      })}

      <div className={styles.dineInSubtitleWrapper}>
        <h4 className={styles.dineInSubtitle}>
          Setup Service Charge for Dine In
        </h4>
        <button
          className={styles.addNewButton}
          type="button"
          onClick={handleAdd}
        >
          Add new
        </button>
      </div>

      {sortedServiceCharges.map((charge) => {
        if (charge.order_type === "DineIn") {
          return (
            <ServiceChargeForm
              key={charge.id}
              id={charge.id}
              typeName={charge.order_type}
              initialName={charge.name}
              initialRate={charge.percentage}
              initialStatus={charge.is_enabled}
              initialMinGuestCount={charge.min_guest_count}
              initialMaxGuestCount={charge.max_guest_count}
            />
          );
        }
      })}

      {formForNewCharge.length > 0 && (
        <ServiceChargeForm
          key={formForNewCharge[0].id}
          id={formForNewCharge[0].id}
          typeName={formForNewCharge[0].order_type}
          initialName={formForNewCharge[0].name}
          initialRate={formForNewCharge[0].percentage}
          initialStatus={formForNewCharge[0].is_enabled}
          initialMinGuestCount={formForNewCharge[0].min_guest_count}
          initialMaxGuestCount={formForNewCharge[0].max_guest_count}
          setFormForNewCharge={setFormForNewCharge}
          isFormForCreating={true}
        />
      )}
    </div>
  );
}

export default ServiceChanges;
