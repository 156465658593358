import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../../context/context';
import { updateExistingEmployee } from '../../../../redux/employeesSlice';

import { IoIosArrowDown } from 'react-icons/io';

import PageTitle from '../../../../components/utilities/page-title/page-title';
import Input from '../../../utilities/input/input';
import Selector from '../../../utilities/selector/selector';
import Button from '../../../../components/utilities/button/button';
import BackdropLoading from '../../../utilities/backdrop-loading/backdrop-loading';

import styles from './inactive-employees.module.scss';

const valueConverter = (value, type) => {
  return type === 'number' ? parseFloat(value) : value;
};

const getEmployee = (employees, id) => {
  return employees.find((item) => item.id === parseFloat(id));
};

const EditInactiveEmployees = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const employees = useSelector((state) => state.employees.employees);
  const employeesStatus = useSelector((state) => state.employees.status);
  const rolesOptions = useSelector((state) => state.roles.nameOptions);
  const [employee, setEmployee] = useState(getEmployee(employees, id));

  const statusLoading = employeesStatus === 'loading';

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setEmployee((prev) => ({
      ...prev,
      [name]: valueConverter(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/employee/inactive_employees');
  };

  const handleSaveChanges = async () => {
    try {
      await dispatch(updateExistingEmployee({ id, updatedDetails: employee, token })).unwrap();
      navigate('/employee/inactive_employees');
    } catch {}
  };

  return (
    <section className={styles.employee_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Inactive Employee'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={styles.field}>
              <Input
                label={'First name'}
                value={employee.first_name}
                onChange={handleInputChange}
                name={'first_name'}
                required={true}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Last name'}
                value={employee.last_name}
                onChange={handleInputChange}
                name={'last_name'}
                required={true}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Email address'}
                type="email"
                value={employee.email}
                onChange={handleInputChange}
                name={'email'}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Mobile number'}
                type="string"
                value={employee.phone_number}
                onChange={handleInputChange}
                name={'phone_number'}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Passcode'}
                type="text"
                value={employee.passcode}
                onChange={handleInputChange}
                name={'passcode'}
                required={true}
              />
            </div>
            <div className={styles.field}>
              <Input
                label={'Hourly Rate'}
                type="number"
                value={employee.hourly_wages}
                onChange={handleInputChange}
                name={'hourly_wages'}
              />
            </div>
            <div className={styles.field}>
              <Selector
                icon={IoIosArrowDown}
                label={'Role'}
                items={rolesOptions}
                selectedItem={
                  rolesOptions.find((option) => (option.value === employee.team_role_id ? option.value : null))
                    ?.value ?? employee.team_role_id
                }
                onSelectItem={(value) =>
                  handleInputChange({ target: { name: 'team_role_id', value: value } }, 'number')
                }
                initialPlaceholderVisibility={false}
                required={true}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Save Changes'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditInactiveEmployees;
