import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/context";
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

//import { allCountries } from "country-telephone-data";
import moment from "moment-timezone";

import {
  fetchBusinessInformation,
  updateExistingBusinessInformation,
} from "../../../../redux/informationSlice";

import Selector from "../../../utilities/selector/selector";
import Modal from "../../../utilities/modal/modal";

import { IoIosArrowDown } from "react-icons/io";
import { FaPlus } from "react-icons/fa";

import clsx from "clsx";
import styles from "./information.module.scss";

/* const countries = allCountries.map((country) => ({
  label: `${country.name}`,
  value: `${country.iso2}-${country.dialCode}`,
  iso2: country.iso2,
})); */

const timezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).format("Z");
  return {
    label: `(GMT${offset}) ${timezone}`,
    value: `(GMT${offset}) ${timezone}`,
  };
});

const validateCoordinates = (latitude, longitude) => {
  const lat = parseFloat(latitude);
  const lon = parseFloat(longitude);

  if (isNaN(lat) || lat < -90 || lat > 90) {
    return "Latitude must be a number between -90 and 90.";
  }

  if (isNaN(lon) || lon < -180 || lon > 180) {
    return "Longitude must be a number between -180 and 180.";
  }

  return null;
};

function Information() {
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Get data from the store
  const business_information = useSelector(
    (state) => state.business_information.business_information
  );

  const businessInformationStatus = useSelector(
    (state) => state.business_information.status
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyC0mWJ-DJCIYIjFYghXTBOQLK6pqIWF9T8',
    libraries: ['places'],
  });

  const countryAutocompleteRef = useRef(null);
  const stateAutocompleteRef = useRef(null);
  const cityAutocompleteRef = useRef(null);
  const streetAutocompleteRef = useRef(null);

  // Set initial state
  const [businessName, setBusinessName] = useState("");
  const [businessPhoneNumber1, setBusinessPhoneNumber1] = useState("");
  const [businessPhoneNumber2, setBusinessPhoneNumber2] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [businessTimezone, setBusinessTimezone] = useState("");

  const [businessCountry, setBusinessCountry] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessStreet, setBusinessStreet] = useState("");
  const [businessApartment, setBusinessApartment] = useState("");
  const [businessPostCode, setBusinessPostCode] = useState("");
  const [businessLatitude, setBusinessLatitude] = useState(0);
  const [businessLongitude, setBusinessLongitude] = useState(0);

  const [businessLogoContent, setBusinessLogoContent] = useState("");
  const [businessLogoFileName, setBusinessLogoFileName] = useState("");

  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (businessInformationStatus === "idle" && token && locationId) {
      dispatch(fetchBusinessInformation(token, locationId));
    }
  }, [dispatch, businessInformationStatus, token, locationId]);

  useEffect(() => {
    if (business_information && business_information.length > 0) {
      const restaurant_information = business_information.find(
        (item) => item.business_id === locationId
      );

      if (restaurant_information) {
        const {
          business_address = {},
          business_details = {},
          logo = {},
        } = restaurant_information;

        setBusinessName(business_details.name ? business_details.name : "");
        setBusinessPhoneNumber1(
          business_details.phone_number ? business_details.phone_number : ""
        );
        setBusinessPhoneNumber2(
          business_details.phone_number2 ? business_details.phone_number2 : ""
        );
        setBusinessEmail(business_details.email ? business_details.email : "");
        setBusinessWebsite(
          business_details.website ? business_details.website : ""
        );
        setBusinessTimezone(
          business_details.timezone ? business_details.timezone : ""
        );
        setBusinessCountry(
          business_address.country ? business_address.country : ""
        );
        setBusinessState(business_address.state ? business_address.state : "");
        setBusinessCity(business_address.city ? business_address.city : "");
        setBusinessStreet(
          business_address.street ? business_address.street : ""
        );
        setBusinessApartment(
          business_address.apartment ? business_address.apartment : ""
        );
        setBusinessPostCode(
          business_address.postcode ? business_address.postcode : ""
        );
        setBusinessLatitude(
          business_address.latitude ? business_address.latitude : 0
        );
        setBusinessLongitude(
          business_address.longitude ? business_address.longitude : 0
        );
        setBusinessLogoContent(logo.content ? logo.content : "");
        setBusinessLogoFileName(logo.file_name ? logo.file_name : "");
      }
    }
  }, [business_information, locationId]);

/*   const handlePlaceSelect = (setState) => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      
      if (place && place.formatted_address) {
        const address = place.formatted_address;
        setState(address);
      } else if (place && place.name) {
        // If formatted_address is missing, use name as a fallback
        setState(place.name);
      } else {
        console.warn("No valid address found for the selected place.");
      }
    }
  }; */

  const handlePlaceSelect = (autocompleteRef, setState) => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place) {
        const addressComponents = place.address_components;
        console.log("addressComponents", addressComponents);
        const postalCodeComponent = addressComponents.find(component =>
          component.types.includes("postal_code")
        );

        setState(place.formatted_address || place.name);
        if (postalCodeComponent) {
          setBusinessPostCode(postalCodeComponent.long_name);
        }
      }
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        setBusinessLogoContent(base64String);
        setBusinessLogoFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoClick = () => {
    document.getElementById("logoInput").click();
  };


  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const atSymbolIndex = email.indexOf("@");
    const dotAfterAt = email.slice(atSymbolIndex).indexOf(".") > 0;

    if (!email.includes("@")) {
      return "Please include an '@' in the email address.";
    }

    if (!dotAfterAt) {
      return "Email address should contain a '.'";
    }

    if (!emailRegex.test(email)) {
      return "Invalid email format.";
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validations
    const emailError = validateEmail(businessEmail);

    if (emailError) {
      setModalMessage(emailError);
      setIsModalOpen(true);
      return;
    }

    const locationError = validateCoordinates(
      businessLatitude,
      businessLongitude
    );
    if (locationError) {
      setModalMessage(locationError);
      setIsModalOpen(true);
      return;
    }

    const businessData = {
      business_id: locationId,
      business_details: {
        name: businessName,
        phone_number: businessPhoneNumber1,
        phone_number2: businessPhoneNumber2,
        email: businessEmail,
        website: businessWebsite,
        timezone: businessTimezone,
      },
      business_address: {
        country: businessCountry,
        state: businessState,
        city: businessCity,
        street: businessStreet,
        apartment: businessApartment,
        postcode: businessPostCode,
        latitude: businessLatitude,
        longitude: businessLongitude,
      },
      logo: {
        file_name: businessLogoFileName,
        content: businessLogoContent,
      },
    };

    dispatch(
      updateExistingBusinessInformation({
        id: locationId,
        updatedDetails: businessData,
        token,
      })
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!isLoaded) return <div>Loading Google Maps...</div>;

  console.log("business_information", business_information);
  console.log("businessCountry", businessCountry);

  return (
    <>
      <div className={styles.information}>
        <h2 className={styles.title}>Business Information</h2>
        <div className={styles.wrapper}>
          <form className={styles.form}>
            <div className={styles.main_info}>
              <h3 className={styles.subtitle}>Restaurant Details</h3>
              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Restaurant Name<span>*</span>
                </label>
                <input
                  type="text"
                  required
                  value={businessName}
                  placeholder="Your restaurant name"
                  onChange={(e) => setBusinessName(e.target.value)}
                  style={{ color: "#959A9E" }}
                />
              </div>
              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Restaurant Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  required
                  value={businessPhoneNumber1}
                  onChange={(e) => setBusinessPhoneNumber1(e.target.value)}
                  placeholder="Your restaurant phone number"
                  style={{ color: "#959A9E" }}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Email Address<span>*</span>
                </label>
                <input
                  type="email"
                  required
                  placeholder="Email address"
                  value={businessEmail}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>Restaurant Website</label>
                <input
                  type="email"
                  placeholder="Restaurant website URL"
                  value={businessWebsite}
                  onChange={(e) => setBusinessWebsite(e.target.value)}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Time Zone<span>*</span>
                </label>
                <Selector
                  placeholder={businessTimezone}
                  icon={IoIosArrowDown}
                  items={timezones}
                  selectedItem={businessTimezone}
                  onSelectItem={setBusinessTimezone}
                  className={styles.selector}
                  style={{ color: "#959a9e" }}
                />
              </div>
              <h4 className={styles.subtitle}>Restaurant Address</h4>

              <div className={styles.section}>
                {/* <label>Country</label> */}
                <Autocomplete
                  onLoad={autocomplete => (countryAutocompleteRef.current = autocomplete)}
                  onPlaceChanged={() => handlePlaceSelect(countryAutocompleteRef, setBusinessCountry)}
                  className={styles.autocomplete}
                >
                  <input
                    type="text"
                    placeholder="Country"
                    value={businessCountry}
                    onChange={e => setBusinessCountry(e.target.value)}
                  />
                </Autocomplete>
                {/* <input
                  type="text"
                  required
                  placeholder="Country"
                  value={businessCountry}
                  onChange={(e) => setBusinessCountry(e.target.value)}
                /> */}
              </div>

{/*               <div className={styles.section} style={{ marginTop: "24px" }}>
                <label>State</label>
                <Autocomplete
                  onLoad={autocomplete => (stateAutocompleteRef.current = autocomplete)}
                  onPlaceChanged={() => handlePlaceSelect(stateAutocompleteRef, setBusinessState)}
                  className={styles.autocomplete}
                >
                  <input
                    type="text"
                    placeholder="State"
                    value={businessState}
                    onChange={e => setBusinessState(e.target.value)}
                    className={clsx(styles.input, styles.address_group)}
                  />
                </Autocomplete>
              </div> */}

{/*               <div className={styles.section} style={{ marginTop: "24px" }}>
                <label>City</label>
                <Autocomplete
                  onLoad={autocomplete => (cityAutocompleteRef.current = autocomplete)}
                  onPlaceChanged={() => handlePlaceSelect(cityAutocompleteRef, setBusinessCity)}
                  className={styles.autocomplete}
                >
                  <input
                    type="text"
                    placeholder="City"
                    value={businessCity}
                    onChange={e => setBusinessCity(e.target.value)}
                    className={clsx(styles.input, styles.address_group)}
                  />
                </Autocomplete>
              </div> */}
{/* 
              <div className={styles.section} style={{ marginTop: "24px" }}>
                <label>Street</label>
                <Autocomplete
                  onLoad={autocomplete => (streetAutocompleteRef.current = autocomplete)}
                  onPlaceChanged={() => handlePlaceSelect(streetAutocompleteRef, setBusinessStreet)}
                  className={styles.autocomplete}
                >
                  <input
                    type="text"
                    placeholder="Street"
                    value={businessStreet}
                    onChange={e => setBusinessStreet(e.target.value)}
                    className={clsx(styles.input, styles.address_group)}
                    required
                  />
                </Autocomplete>
              </div> */}

{/*               <div className={styles.section} style={{ marginTop: "24px" }}>
                <label>Apt / Suite</label>
                <input
                  type="text"
                  placeholder="Apt / Suite"
                  value={businessApartment}
                  onChange={(e) => setBusinessApartment(e.target.value)}
                  className={clsx(styles.input, styles.address_group)}
                />
              </div> */}

{/*               <div className={styles.section} style={{ marginTop: "24px", marginBottom: "30px" }}>
                <label>Post Code</label>
                <input
                  type="text"
                  placeholder="Post Code"
                  value={businessPostCode}
                  onChange={(e) => setBusinessPostCode(e.target.value)}
                  className={styles.input}
                  required
                />
              </div> */}

              <button
                className={styles.submitButton}
                type="submit"
                onClick={handleSubmit}
              >
                UPDATE
              </button>
            </div>

            <div className={styles.section}>
              <div className={styles.add_logo}>
                <div
                  className={styles.logo_container}
                  onClick={handleLogoClick}
                >
                  {businessLogoContent ? (
                    <img
                      src={businessLogoContent}
                      alt="Logo Preview"
                      className={styles.logo_image}
                    />
                  ) : (
                    <FaPlus className={styles.plus_icon} />
                  )}
                </div>
                <div className={styles.logo_footer} onClick={handleLogoClick}>
                  <p>Add Logo</p>
                </div>
                <input
                  type="file"
                  id="logoInput"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        message={modalMessage}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        className={styles.business_modal}
      />
    </>
  );
}

export default Information;
