import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/context";

import {
  fetchTips,
  addTip,
  updateExistingTip,
  removeTip,
  updateExistingTipStatus,
} from "../../../../redux/tipsSlice";

import TipsList from "./tips-list";
import PageTitle from "../../../utilities/page-title/page-title";
import Button from "../../../utilities/button/button";
import Input from "../../../utilities/input/input";

import clsx from "clsx";
import styles from "./tips.module.scss";

function Tips() {
  const dispatch = useDispatch();

  const tips = useSelector((state) => state.tips.tips);
  const tipsStatus = useSelector((state) => state.tips.status);

  const { token, locationId } = useAppContext();

  const [newTip, setNewTip] = useState("");
  const [newTipPercent, setNewTipPercent] = useState("");
  const [editedTips, setEditedTips] = useState({});

  useEffect(() => {
    if (token) {
      dispatch(fetchTips(token));
    }
  }, [dispatch, token]);

  const handleInputChange = useCallback(
    (id, field, value) => {
      const existingTip = tips.find((tip) => tip.id === id);

      if (existingTip) {
        setEditedTips((prev) => ({
          ...prev,
          [id]: {
            ...existingTip,
            ...prev[id],
            [field]: value,
          },
        }));
      }
    },
    [tips]
  );

  const handleAdd = () => {
    const largestId = tips.reduce(
      (max, tip) => (tip.id > max ? tip.id : max),
      0
    );
    const newDetails = {
      id: largestId + 1,
      is_active: true,
      location_id: locationId,
      name: newTip,
      rate: newTipPercent,
    };
    dispatch(addTip({newDetails, token}));
  };

  const handleDelete = useCallback(
    (id) => {
      dispatch(removeTip({id, token}));
    },
    [dispatch]
  );

  const handleUpdate = async () => {
    try {
      await Promise.all(
        Object.entries(editedTips).map(([id, updatedDetails]) =>
          dispatch(updateExistingTip({ id, updatedDetails, token }))
        )
      );
      setEditedTips({});
    } catch (error) {
      console.error("Error updating tips:", error);
    }
  };

  const handleToggleActive = useCallback(
    (id, value) => {
      dispatch(updateExistingTipStatus({ id, is_active: value, token }));
    },
    [dispatch, token]
  );

  return (
    <div className={styles.tips}>
      <PageTitle name={"Tips"} />
      <div className={styles.add_tip}>
        <div className={styles.section}>
          <Input
            label={"Tip Name"}
            value={newTip}
            onChange={(e) => setNewTip(e.target.value)}
            placeholder={"Tip Name"}
            required={true}
            className={styles.tip_name_input}
          />
        </div>
        <div className={styles.section}>
          <Input
            label={"Tip %"}
            type="number"
            value={newTipPercent}
            onChange={(e) => setNewTipPercent(e.target.value)}
            placeholder={"0.00%"}
            required={true}
            className={styles.tip_percent_input}
          />
        </div>
        <Button
          className={styles.add_button}
          title={"Add Tips"}
          onClick={handleAdd}
        />
      </div>

      <TipsList
        items={tips}
        onEdit={handleInputChange}
        onDelete={handleDelete}
        onToggleActive={handleToggleActive}
        isLoading={tipsStatus === "loading"}
        editedTips={editedTips}
        setEditedTips={setEditedTips}
      />
      <Button
        className={styles.update_button}
        title={"Update"}
        onClick={handleUpdate}
      />
    </div>
  );
}

export default Tips;
