import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppContext } from '../../../context/context';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import 'chart.js';

import {
  fetchMetricsData,
} from '../../../redux/dashboardSlice';

import Button from "../../utilities/button/button";
import PageTitle from "../../utilities/page-title/page-title";
import Metric from "./statistics/metric";
import NetSalesBarChart from './graphs/net-sales/net-sales';
import GrossSalesBarChart from './graphs/gross-sales/gross-sales';

import qrCode from "../../../assets/img/qr_code.png";

import clsx from "clsx";
import styles from './dashboard.module.scss';

const adjustForTimeZone = (date) => {
  const offsetInMinutes = date.getTimezoneOffset();
  return new Date(date.getTime() - offsetInMinutes * 60000);
};

function Dashboard() {
  const dispatch = useDispatch();
  const { token } = useAppContext();

  const dashboard_data = useSelector((state) => state.dashboard.dashboard_data);

  // Local state
  const today = new Date();
  const [fromDate, setFromDate] = useState(today);
  const [fromDateISO, setFromDateISO] = useState(null);
  const [toDate, setToDate] = useState(today);
  const [toDateISO, setToDateISO] = useState(null);

  
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(fetchMetricsData({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (dashboard_data) {
      setMetrics(dashboard_data);
    }
  }, [dashboard_data]);

  // Effects
  useEffect(() => {
    if (!fromDate ||!toDate) return;

    const adjustedClockIn = adjustForTimeZone(fromDate);
    const adjustedClockOut = adjustForTimeZone(toDate);

    setFromDateISO(adjustedClockIn.toISOString());
    setToDateISO(adjustedClockOut.toISOString());

  }, [fromDate, toDate]);

  // Handlers
  const handleSelectFromDate = (date) => {
    setFromDate(new Date(date));
  };

  const handleSelectToDate = (date) => {
    setToDate(new Date(date));
  };

  const handleAddNewItem = () => {
    console.log("fromDateISO: " + fromDateISO);
    console.log("toDateISO: " + toDateISO);
  };

  const netSalesData = {
    today: 150,
    yesterday: 250,
    thisWeek: 400,
    thisMonth: 550
  };

  const grossSalesData = {
    today: 170,
    yesterday: 100,
    thisWeek: 410,
    thisMonth: 560
  };
  
  console.log("metrics: ", metrics);

  return (
    <div className={styles.dashboard}>
      <PageTitle name={"Dashboard"} />
      <div className={styles.filters}>
        <DatePicker
          selected={fromDate}
          onChange={(date) => handleSelectFromDate(date)}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => handleSelectToDate(date)}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <Button
          className={styles.search_button}
          title={"Search"}
          onClick={handleAddNewItem}
        />
      </div>
      <div className={styles.metrics}>
        <div className={styles.row}>
          <div className={styles.graph_item}>
            <PageTitle name={"Net Sales"} className={styles.subtitle} />
            <div className={styles.net_sales_wrapper}>
              <NetSalesBarChart data={netSalesData} />
            </div>
          </div>
          <div className={styles.graph_item}>
            <PageTitle name={"Gross Sales"} className={styles.subtitle} />
            <div className={styles.net_sales_wrapper}>
              <GrossSalesBarChart data={grossSalesData} />
            </div>
          </div>
          <div className={styles.statistics}>
            <PageTitle name={"Today`s Statistics"} className={styles.subtitle} />
            <div className={styles.metrics}>
              {metrics && metrics.map((metric) => <Metric item={metric} key={metric.id} />)}
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.graph_item_full}>
            <PageTitle name={"Hourly New Revenue"} className={styles.subtitle} />
            <div className={styles.graph_content}>No records available</div>
          </div>
        </div>
        
        <div className={styles.row}>
          <div className={styles.graph_item}>
            <PageTitle name={"Payment Methods"} className={styles.subtitle} />
            <div className={styles.graph_content}>No records available</div>
          </div>
          <div className={styles.graph_item}>
            <PageTitle name={"Discounts"} className={styles.subtitle} />
            <div className={styles.graph_content}>No records available</div>
          </div>
          <div className={styles.statistics}>
            <PageTitle name={"Guests"} className={styles.subtitle} />
            <div className={styles.graph_content}>Statisctic blocks</div>
          </div>
        </div>

        <div className={clsx(styles.row, styles.row_align_left)}>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            <PageTitle name={"Clocked-In-Employees"} className={styles.subtitle} />
            <div className={styles.graph_content}>No records available</div>
          </div>
          <div className={clsx(styles.graph_item, styles.graph_item_align_left)}>
            <PageTitle name={"Top Selling Items"} className={styles.subtitle} />
            <div className={styles.graph_content}>No records available</div>
          </div>
        </div>

      </div>

      <div className={styles.footer}>
        <div className={styles.links}>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Home Page</span>
            <Link to="/business/information" className={styles.link}>https://somewebsite.com</Link>
          </div>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Menu Page</span>
            <Link to="/menu/items" className={styles.link}>https://anotherwebsite.com</Link>
          </div>
        </div>
        <div className={styles.qr_code_wrapper}>
          <div className={styles.qr_code}>
            <img src={qrCode} alt="QR Code" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard;