import endOfDayReportStore from './endOfDayReportStore';

export const api = {
  get: (url, config) => {
    return new Promise((resolve, reject) => {
      if (!!url && !!config.headers.TOKEN) {
        setTimeout(() => resolve({ data: { data: endOfDayReportStore.getDayReport() } }), 1000);
      } else {
        reject(new Error('Token or url is not provided'));
      }
    });
  },
  put: (url, data, config) => {
    return new Promise((resolve, reject) => {
      if (!!url && !!config.headers.TOKEN) {
        setTimeout(() => {
          endOfDayReportStore.updateDayReport(data);
          resolve({ data: { data } });
        }, 1000);
      } else {
        reject(new Error('Token or url or ID is not provided'));
      }
    });
  },
};
