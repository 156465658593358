import { api, handleApiResponse } from "./base";


export const getServiceCharges = (token) =>
  handleApiResponse(() =>
    api.get("service_charges", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addServiceCharge = (newServiceChargeDetails, token) =>
  handleApiResponse(() =>
    api.post("service_charges", newServiceChargeDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateServiceCharge = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`service_charges/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const deleteServiceCharge = (id, token) =>
  handleApiResponse(() =>
    api.delete(`service_charges/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateServiceChargeStatus = (id, is_enabled, token) =>
  handleApiResponse(() =>
    api.put(`service_charges/${id}/active?is_enabled=${is_enabled}`, {
      headers: {
        TOKEN: token,
      },
    })
  );
