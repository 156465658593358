import { api, handleApiResponse } from "./base";

export const getItems = (token) =>
  handleApiResponse(() =>
    api.get("items", {
      headers: {
        TOKEN: token,
      },
    })
  );

export const addItem = (newDetails, token) =>
  handleApiResponse(() =>
    api.post("items", newDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const getItem = (id, token) =>
  handleApiResponse(() =>
    api.get(`items/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const updateItem = (id, updatedDetails, token) =>
  handleApiResponse(() =>
    api.put(`items/${id}`, updatedDetails, {
      headers: {
        TOKEN: token,
      },
    })
  );

export const deleteItem = (id, token) =>
  handleApiResponse(() =>
    api.delete(`items/${id}`, {
      headers: {
        TOKEN: token,
      },
    })
  );

// Maybe we shoold use an empty {} here like a second parameter
export const updateItemStatusPOS = (id, status, token) =>
  handleApiResponse(() =>
    api.put(
      `items/${id}/active_pos?hide_status=${status}`, {},
      {
        headers: {
          TOKEN: token,
        },
      }
    )
  );

  // I am not sure about using an empty {} here
export const updateItemStatusWebsite = (id, status, token) =>
  handleApiResponse(() =>
    api.put(`items/${id}/active_web_site?website_hide_status=${status}`, {}, {
      headers: {
        TOKEN: token,
      },
    })
  );
