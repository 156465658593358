import React from "react";

import Modal from "../../../../components/utilities/modal/modal";
import Selector from "../../../../components/utilities/selector/selector";

import { IoIosArrowDown } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";

import styles from "./sales-taxes-edit-modal.module.scss";

const SELECTOR_OPTIONS = [
  { value: "Percentage", label: "Percentage" },
  { value: "Dollar", label: "Dollar" },
];

function EditSalesTaxesModal({
  isModalOpen,
  setIsModalOpen,
  editableData,
  setEditableData,
  onUpdate,
}) {
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditableData({});
  };

  const handleSelectType = (selectedValue) => {
    setEditableData((prevState) => ({
      ...prevState,
      editableType: selectedValue,
    }));
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleCloseModal}
      className={styles.edit_taxes_modal}
    >
      <div className={styles.modal_content}>
        <div className={styles.modal_header}>
          <h3 className={styles.modal_title}>
            Edit {editableData.editableName}
          </h3>
          <div className={styles.cross_icon} onClick={handleCloseModal}>
            <RxCross2 />
          </div>
        </div>
        <div className={styles.modal_body}>
          <div className={styles.edit_input_wrapper}>
            <label>
              Tax Name<span>*</span>
            </label>
            <input
              type="text"
              required
              value={editableData.editableName}
              placeholder="New name"
              onChange={(e) =>
                setEditableData((prevState) => ({
                  ...prevState,
                  editableName: e.target.value,
                }))
              }
            />
          </div>

          <div className={styles.edit_input_wrapper}>
            <label>
              Tax Rate<span>*</span>
            </label>
            <input
              type="number"
              required
              value={editableData.editableRate}
              placeholder="New rate"
              onChange={(e) =>
                setEditableData((prevState) => ({
                  ...prevState,
                  editableRate: Number(e.target.value),
                }))
              }
            />
          </div>

          <div className={styles.edit_input_wrapper}>
            <label>
              Type<span>*</span>
            </label>
            <Selector
              icon={IoIosArrowDown}
              items={SELECTOR_OPTIONS}
              placeholder={editableData.editableType}
              onSelectItem={handleSelectType}
              selectedItem={editableData.editableType}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.update_button}
            onClick={onUpdate}
          >
            Update
          </button>
          <button
            type="button"
            className={styles.cancel_button}
            onClick={handleCloseModal}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditSalesTaxesModal;
